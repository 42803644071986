import React, { useState, useEffect } from 'react';
import '../assets/css/TourPage.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCalendarDays,
  faChevronLeft,
  faChevronRight,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import DatePicker from 'react-datepicker';
import { useLocation } from 'react-router-dom';
import TourCard from './TourCard';
import { useDispatch, useSelector } from 'react-redux';

import { CiMap } from 'react-icons/ci';
import { CiImageOn } from 'react-icons/ci';
import { BsFilterRight } from 'react-icons/bs';

import { fetchAllTours, fetchTourDetails, searchTours } from '../redux/home';
import axios from 'axios';

export const CustomHeader = ({ date, decreaseMonth, increaseMonth }) => {
  const monthName = date.toLocaleString('default', { month: 'long' });

  return (
    <div className='custom-header'>
      <button onClick={decreaseMonth} aria-label='Previous Month'>
        <FontAwesomeIcon icon={faChevronLeft} />
      </button>
      <span className='month-name'>{monthName}</span>
      <button onClick={increaseMonth} aria-label='Next Month'>
        <FontAwesomeIcon icon={faChevronRight} />
      </button>
    </div>
  );
};

const TourPage = () => {
  const regions = [
    'Antarctica',
    'Asia',
    'Australia',
    'Central America',
    'South America',
    'North America',
    'Middle East',
    'Europe',
  ];

  const location = useLocation();
  const dispatch = useDispatch();
  const { allTours, searchResults, tourDetails, conversionRates, isLoading } =
    useSelector((state) => state.Tours);
  const [displayedTours, setDisplayedTours] = useState([]);
  const [imageBannerView, setImageBannerView] = useState('image');
  const [budgetValue, setBudgetValue] = useState(15000);
  const [durationValue, setDurationValue] = useState(30);
  const [departureBefore, setDepartureBefore] = useState(new Date());
  const [departureAfter, setDepartureAfter] = useState(new Date());
  const [isBeforeOpen, setIsBeforeOpen] = useState(false);
  const [isAfterOpen, setIsAfterOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [apiPage, setApiPage] = useState(1);
  const [appliedFilters, setAppliedFilters] = useState([]);
  const [allDaysArray, setAllDaysArray] = useState([]);
  const [sortOption, setSortOption] = useState('');
  const [selectedRegions, setSelectedRegions] = useState([]); // Array of selected regions
  const [selectedRegion, setSelectedRegion] = useState('');
  const toursPerPage = 6;

  const searchParams = new URLSearchParams(location.search);
  const searchInput = searchParams.get('name') || '';
  const apikey = process.env.REACT_APP_API_KEY;

  const [isFiltersVisible, setFiltersVisible] = useState(false);

  const toggleFilters = () => {
    setFiltersVisible(!isFiltersVisible);
  };
  useEffect(() => {
    if (selectedRegion !== '' || searchInput !== '') {
      dispatch(
        searchTours({ inputValue: selectedRegion || searchInput, apikey })
      );
    } else {
      dispatch(fetchAllTours({ apikey, page: apiPage }));
    }
  }, [dispatch, apikey, apiPage, selectedRegion, searchInput]);

  useEffect(() => {
    if (searchResults.length > 0) {
      dispatch(fetchTourDetails({ searchResults, apikey }));
    }
  }, [dispatch, searchResults, apikey]);

  const updateSliderStyle = (value, max) => {
    return {
      '--value': `${(value / max) * 100}%`,
    };
  };

  const handleApplyFilter = (filterName) => {
    if (!appliedFilters.includes(filterName)) {
      setAppliedFilters([...appliedFilters, filterName]);
    }
  };

  // Handle removing a filter
  const handleRemoveFilter = (filterName) => {
    setAppliedFilters(appliedFilters.filter((f) => f !== filterName));
  };

  // Clear all filters
  const clearAllFilters = () => {
    setAppliedFilters([]);
  };

  useEffect(() => {
    if (!tourDetails) return;

    const fetchItinerary = async (href) => {
      try {
        const response = await axios.get(href, {
          headers: {
            'X-Application-Key': apikey,
          },
        });

        return response.data.days;
      } catch (error) {
        console.error('Error fetching itinerary:', error);
        return null;
      }
    };

    const fetchAllItineraries = async () => {
      const promises = [];

      // Iterate through each tourId in the tourDetails object
      Object.keys(tourDetails).forEach((tourId) => {
        const { href } = tourDetails[tourId]?.structured_itineraries?.[0];
        if (href) {
          promises.push(fetchItinerary(href));
        } else {
          console.warn(`No href found for tourId: ${tourId}`);
        }
      });

      // Wait for all promises to resolve and get all the days
      const allDays = await Promise.all(promises);
      setAllDaysArray(allDays);
    };

    fetchAllItineraries();
  }, [tourDetails, apikey]);

  const toggleBeforeDatePicker = () => setIsBeforeOpen(!isBeforeOpen);
  const toggleAfterDatePicker = () => setIsAfterOpen(!isAfterOpen);

  // Dropdown for sorting options
  const handleSortChange = (event) => {
    setSortOption(event.target.value);
  };

  // Duration filter function
  const filterByDuration = (tourDays, durationValue) => {
    return durationValue === null || tourDays.length <= durationValue;
  };

  // Region filter function
  const filterByRegion = (tourRegionObject, selectedRegions) => {
    const tourRegions = [tourRegionObject?.name];
    return (
      selectedRegions.length === 0 ||
      tourRegions.some((region) => selectedRegions.includes(region))
    );
  };

  const filterByAmount = (tour, sliderValue, conversionRates) => {
    // Get the list of available currencies from conversionRates
    const availableCurrencies = Object.keys(conversionRates);

    // Find the advertised departure in the available currencies or take the first available
    const priceInfo =
      tour.advertised_departures?.find((dep) =>
        availableCurrencies.includes(dep.currency)
      ) || tour.advertised_departures?.[0];

    const price = priceInfo ? priceInfo.amount : null;

    // Return false if no valid price exists
    if (price === null || isNaN(price)) {
      return false;
    }

    // Convert the price to USD using conversion rates
    const currency = priceInfo?.currency || 'USD';
    const conversionRate = conversionRates[currency] || 1; // Default to 1 if USD
    const convertedPrice = price / conversionRate;

    // Check if the converted price is within the slider value range
    return convertedPrice <= sliderValue;
  };

  // Combined filter logic
  const filteredTours = searchResults.filter((tour, index) => {
    const tourRegionObject = tourDetails[tour.id]?.geography?.region || {};
    const tourDays = allDaysArray[index] || [];

    // Filter by duration and region
    const durationFilter = filterByDuration(tourDays, durationValue);
    const regionFilter = filterByRegion(tourRegionObject, selectedRegions);
    const amountFilter = filterByAmount(tour, budgetValue, conversionRates);

    if (durationValue === 0 && selectedRegions.length === 0) {
      return true;
    }

    // return durationFilter && regionFilter;
    return durationFilter && regionFilter && amountFilter;
  });

  // Sort tours based on selected sort option
  const sortedTours = [...filteredTours].sort((a, b) => {
    const aDays = allDaysArray[searchResults.indexOf(a)]?.length || 0;
    const bDays = allDaysArray[searchResults.indexOf(b)]?.length || 0;

    // Get the price for each tour (converted to USD)
    const aPriceInfo =
      a.advertised_departures?.find((dep) =>
        Object.keys(conversionRates).includes(dep.currency)
      ) || a.advertised_departures?.[0];

    const bPriceInfo =
      b.advertised_departures?.find((dep) =>
        Object.keys(conversionRates).includes(dep.currency)
      ) || b.advertised_departures?.[0];

    const aPrice = aPriceInfo ? aPriceInfo.amount : null;
    const bPrice = bPriceInfo ? bPriceInfo.amount : null;

    const aConvertedPrice =
      aPrice / (conversionRates[aPriceInfo?.currency] || 1);
    const bConvertedPrice =
      bPrice / (conversionRates[bPriceInfo?.currency] || 1);

    if (sortOption === 'durationUp') {
      return aDays - bDays; // Ascending order
    } else if (sortOption === 'durationDown') {
      return bDays - aDays; // Descending order
    } else if (sortOption === 'priceUp') {
      return aConvertedPrice - bConvertedPrice; // Ascending by price
    } else if (sortOption === 'priceDown') {
      return bConvertedPrice - aConvertedPrice; // Descending by price
    } else {
      return 0; // No sorting
    }
  });

  // Pagination
  const totalPages = Math.ceil(sortedTours.length / toursPerPage);
  const indexOfLastTour = currentPage * toursPerPage;
  const indexOfFirstTour = indexOfLastTour - toursPerPage;
  // const currentTours = sortedTours.slice(indexOfFirstTour, indexOfLastTour);

  // Ensure the current page doesn't exceed total pages
  if (currentPage > totalPages) {
    // Set currentPage to totalPages if it exceeds
    setCurrentPage(totalPages);
  }

  // Calculate currentTours
  const currentTours = sortedTours.slice(
    indexOfFirstTour,
    Math.min(indexOfLastTour, sortedTours.length)
  );

  const handleRegionChange = (event, regionName) => {
    setApiPage(1);
    setCurrentPage(1);
    const isChecked = event.target.checked; // Check if the checkbox is checked
    isChecked ? setSelectedRegion(regionName) : setSelectedRegion('');
  };

  const scrollToTop = () => {
    const screenWidth = window.innerWidth;
    const scrollPosition = screenWidth < 768 ? 1200 : 0;

    window.scrollTo({
      top: scrollPosition,
      behavior: 'smooth',
    });
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    scrollToTop();
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(
        <button
          style={{ cursor: 'pointer', height: '50px', width: '50px' }}
          key={i}
          onClick={() => handlePageChange(i)}
          className={currentPage === i ? 'active' : ''}
        >
          {i}
        </button>
      );
    }
    return pageNumbers;
  };

  const hasQuery = searchParams.get('name');
  //console.log('apiPage', apiPage);
  return (
    <main>
      <div className='tour-details'>
        <div className='tour-details-text'>
          <h1>
            {sortedTours.length || 0} Unforgettable Adventures{' '}
            {searchInput ? 'in ' + searchInput : ''} Await You!
          </h1>
          <p>
            Handpicked tours with unbeatable experiences—from city vibes to
            nature thrills.
          </p>
        </div>
        <div className='filtered-icons'>
          <div className='image-view-map-view-btn-small'>
            <button
              className={`image-view-btn cursor-pointer ${imageBannerView === 'image' ? 'image-active-btn' : ''
                }`}
              onClick={() => setImageBannerView('image')}
            >
              <span className='icon'>
                <CiImageOn />
              </span>
            </button>
            <button
              className={`map-view-btn cursor-pointer ${imageBannerView === 'map' ? 'map-active-btn' : ''
                }`}
              onClick={() => setImageBannerView('map')}
            >
              <span className='icon'>
                <CiMap />
              </span>
            </button>
          </div>
          <div className='filter-icon'>
            <button onClick={toggleFilters}>
              <span>Filters:</span>
              <BsFilterRight />
            </button>
          </div>
        </div>

        <div className='view-section'>
          <div className='sort-by-view'>
            <p>Sort by:</p>
            <select onChange={handleSortChange} value={sortOption}>
              <option value='relevance'>Relevance</option>
              <option value='priceDown'>↘ Price</option>
              <option value='priceUp'>↗ Price</option>
              <option value='durationDown'>↘ Duration</option>
              <option value='durationUp'>↗ Duration</option>
            </select>
          </div>
          <div className='image-view-map-view-btn'>
            <button
              type='button'
              // className='image-view-btn cursor-pointer'
              className={`image-view-btn cursor-pointer ${imageBannerView === 'image' ? 'image-active-btn' : ''
                }`}
              onClick={() => setImageBannerView('image')}
            >
              Image View
            </button>
            <button
              type='button'
              // className='map-view-btn cursor-pointer'
              className={`map-view-btn cursor-pointer ${imageBannerView === 'map' ? 'map-active-btn' : ''
                }`}
              onClick={() => setImageBannerView('map')}
            >
              Map View
            </button>
          </div>
        </div>
      </div>
      <div className='tour-selection'>
        <div className='sliders-container'>
          <div className='budget-slider'>
            <label htmlFor='budget'>Budget:</label>
            <input
              type='range'
              id='budget'
              name='budget'
              min='0'
              max='15000'
              step='100'
              value={budgetValue}
              onChange={(e) => setBudgetValue(e.target.value)}
              style={updateSliderStyle(budgetValue, 15000)}
            />
            <span id='budget-value'>${budgetValue}</span>
          </div>

          <div className='duration-slider'>
            <label htmlFor='duration'>Duration:</label>
            <input
              type='range'
              id='duration'
              name='duration'
              min='1'
              max='30'
              step='1'
              value={durationValue}
              onChange={(e) => setDurationValue(e.target.value)}
              style={updateSliderStyle(durationValue, 30)}
            />
            <span id='duration-value'>{durationValue} days</span>
          </div>
        </div>

        <div className='calenders'>
          <div className='departure-before'>
            <i onClick={toggleBeforeDatePicker}>
              <FontAwesomeIcon icon={faCalendarDays} />
            </i>
            <p onClick={toggleBeforeDatePicker}>Departure Before</p>
            {isBeforeOpen && (
              <DatePicker
                renderCustomHeader={CustomHeader}
                selected={departureBefore}
                onChange={(date) => setDepartureBefore(date)}
                inline
                onClickOutside={() => setIsBeforeOpen(false)}
              />
            )}
          </div>
          <hr />
          <div className='departure-after'>
            <i onClick={toggleAfterDatePicker}>
              <FontAwesomeIcon icon={faCalendarDays} />
            </i>
            <p onClick={toggleAfterDatePicker}>Departure After</p>
            {isAfterOpen && (
              <DatePicker
                renderCustomHeader={CustomHeader}
                selected={departureAfter}
                onChange={(date) => setDepartureAfter(date)}
                inline
                onClickOutside={() => setIsAfterOpen(false)}
              />
            )}
          </div>
        </div>
        <div className='filter'>
          <div className='filter-text'>
            <span>Applied Filters:</span>
            <span
              onClick={clearAllFilters}
              style={{ cursor: 'pointer', color: 'blue' }}
            >
              clear all
            </span>
          </div>

          {/* Display applied filters */}
          <div className='applied-filters'>
            {appliedFilters.map((filter, index) => (
              <div key={index} className='filter-box'>
                {filter}
                <FontAwesomeIcon
                  icon={faTimes}
                  className='remove-icon'
                  onClick={() => handleRemoveFilter(filter)}
                  style={{ cursor: 'pointer', marginLeft: '8px' }}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className='tours-section'>
        <div className={'filter-wrapper'}>
          {hasQuery ? (
            <>
              {/* <div className='filter-wrapper'>
                <div className='amazon-program-filter'>
                  <p>Destinations</p>
                  {[
                    'Antarctica',
                    'Asia',
                    'Australia',
                    'Central America',
                    'South America',
                    'North America',
                    'Middle East',
                    'Europe',
                  ].map((region) => (
                    <div className='filter-check-box' key={region}>
                      <input
                        type='checkbox'
                        id={region}
                        onChange={(e) => handleRegionChange(e, region)}
                      />
                      <label htmlFor={region}>{region}</label>
                    </div>
                  ))}
                </div>
              </div> */}
            </>
          ) : (
            <div className='filter-wrapper'>
              <div className='amazon-program-filter'>
                <p>Destinations</p>
                {regions.map((region) => (
                  <div className='filter-check-box' key={region}>
                    <input
                      type='checkbox'
                      id={region}
                      onChange={(e) => {
                        handleApplyFilter(region);
                        handleRegionChange(e, region)
                      }}
                    />
                    <label htmlFor={region}>{region}</label>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
        <div>
          {isFiltersVisible ? (
            <div className={`filter-visible ${isFiltersVisible ? 'open' : ''}`}>
              <div className='sort-by-view'>
                <p>Sort by:</p>
                <select>
                  <option value='relevance'>Relevance</option>
                  <option value='price'>↘ Price</option>
                  <option value='price'>↗ Price</option>
                  <option value='rating'>↘ Duration</option>
                  <option value='price'>↗ Duration</option>
                </select>
              </div>
              <div className={'filter-wrapper'}>
                {hasQuery ? (
                  <>
                    {/* <div className='country-filter'>
                      <p>country filter</p>
                      <div className='filter-check-box'>
                        <input
                          type='checkbox'
                          name=''
                          id='cruise-filter'
                          onChange={() => handleApplyFilter('Cruise')}
                        />
                        <label htmlFor='cruise-filter'>cruise (10)</label>
                      </div>
                      <div className='filter-check-box'>
                        <input
                          type='checkbox'
                          name=''
                          id='back-packing-filter'
                          onChange={() => handleApplyFilter('back packing')}
                        />
                        <label htmlFor='back-packing-filter'>back packing</label>
                      </div>
                      <div className='filter-check-box'>
                        <input
                          type='checkbox'
                          name=''
                          id='first-class'
                          onChange={() => handleApplyFilter('First Class')}
                        />
                        <label htmlFor='first-class'>first class</label>
                      </div>
                      <div className='filter-check-box'>
                        <input
                          type='checkbox'
                          name=''
                          id='mid-range'
                          onChange={() => handleApplyFilter('Mid Range')}
                        />
                        <label htmlFor='mid-range'>Mid range</label>
                      </div>
                    </div> */}
                  </>
                ) : (
                  <div className='filter-wrapper'>
                    <div className='amazon-program-filter'>
                      <p>Destinations</p>
                      {/* <div className='filter-check-box'>
                        <input
                          type='checkbox'
                          name=''
                          id='africa'
                          onChange={() => handleApplyFilter('Africe')}
                        />
                        <label htmlFor='africa'>Africa</label>
                      </div> */}
                      {regions.map((region) => (
                        <div className='filter-check-box' key={region}>
                          <input
                            type='checkbox'
                            id={region}
                            onChange={(e) => {
                              handleApplyFilter(region);
                              handleRegionChange(e, region)
                            }}
                          />
                          <label htmlFor={region}>{region}</label>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
              <div className='calenders'>
                <div className='departure-before'>
                  <i onClick={toggleBeforeDatePicker}>
                    <FontAwesomeIcon icon={faCalendarDays} />
                  </i>
                  <p onClick={toggleBeforeDatePicker}>Departure Before</p>
                  {isBeforeOpen && (
                    <DatePicker
                      renderCustomHeader={CustomHeader}
                      selected={departureBefore}
                      onChange={(date) => setDepartureBefore(date)}
                      inline
                      onClickOutside={() => setIsBeforeOpen(false)}
                    />
                  )}
                </div>
                <hr />
                <div className='departure-after'>
                  <i onClick={toggleAfterDatePicker}>
                    <FontAwesomeIcon icon={faCalendarDays} />
                  </i>
                  <p onClick={toggleAfterDatePicker}>Departure After</p>
                  {isAfterOpen && (
                    <DatePicker
                      renderCustomHeader={CustomHeader}
                      selected={departureAfter}
                      onChange={(date) => setDepartureAfter(date)}
                      inline
                      onClickOutside={() => setIsAfterOpen(false)}
                    />
                  )}
                </div>
              </div>
              <div className='sliders-container'>
                <div className='budget-slider'>
                  <label htmlFor='budget'>Budget:</label>
                  <input
                    type='range'
                    id='budget'
                    name='budget'
                    min='0'
                    max='15000'
                    step='100'
                    value={budgetValue}
                    onChange={(e) => setBudgetValue(e.target.value)}
                    style={updateSliderStyle(budgetValue, 15000)}
                  />
                  <span id='budget-value'>${budgetValue}</span>
                </div>

                <div className='duration-slider'>
                  <label htmlFor='duration'>Duration:</label>
                  <input
                    type='range'
                    id='duration'
                    name='duration'
                    min='1'
                    max='30'
                    step='1'
                    value={durationValue}
                    onChange={(e) => setDurationValue(e.target.value)}
                    style={updateSliderStyle(durationValue, 30)}
                  />
                  <span id='duration-value'>{durationValue} days</span>
                </div>
              </div>
              <div className='filter'>
                <div className='filter-text'>
                  <span>Applied Filters:</span>
                  <span
                    onClick={clearAllFilters}
                    style={{ cursor: 'pointer', color: 'blue' }}
                  >
                    clear all
                  </span>
                </div>

                {/* Display applied filters */}
                <div className='applied-filters'>
                  {appliedFilters.map((filter, index) => (
                    <div key={index} className='filter-box'>
                      {filter}
                      <FontAwesomeIcon
                        icon={faTimes}
                        className='remove-icon'
                        onClick={() => handleRemoveFilter(filter)}
                        style={{ cursor: 'pointer', marginLeft: '8px' }}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ) : null}
        </div>

        <div className='explore-section-cards-tours'>
          {isLoading ? (
            <p>Loading tours...</p>
          ) : (
            currentTours.map((tour) => (
              <TourCard
                imageBannerView={imageBannerView}
                key={tour.id}
                tour={tour}
                tourDetails={tourDetails}
                className='explore-card-tours'
              />
            ))
          )}

          {currentTours.length === 0 && <p>No tours found!</p>}
        </div>
      </div>
      <div className='pagination-controls'>
        <button
          className='prev'
          // onClick={() => handlePageChange(currentPage - 1)}
          onClick={() => {
            setApiPage((prev) => prev - 1);
            handlePageChange(currentPage - 1);
          }}
          disabled={currentPage === 1}
        >
          Previous
        </button>
        {renderPageNumbers()}{' '}
        <span className='text-[40px] text-[#727272]'>...</span>
        <button
          className='next'
          onClick={() => {
            setApiPage((prev) => prev + 1);
            handlePageChange(currentPage + 1);
          }}
        // disabled={currentPage === totalPages}
        >
          Next
        </button>
      </div>
    </main>
  );
};

export default TourPage;
