import { useNavigate } from 'react-router-dom';

const ViewTourHeader = ({ tourDetails }) => {
  // Safely accessing highlightDetail
  const highlightDetail = tourDetails?.details?.find(
    (detail) => detail.detail_type.id === '2'
  );

  const navigate = useNavigate();

  return (
    <section className='picnic-pizza-component'>
      <div className='text-content'>
        {tourDetails ? <h1>{tourDetails.name}</h1> : <h1>Loading...</h1>}
        <p>
          <a href='#' className='cities-link'>
            {highlightDetail ? highlightDetail.body : 'Loading...'}
          </a>
        </p>
      </div>
      <div className='rating-content'>
        <span className='star'>&#9733;</span>
        &nbsp;4.70&nbsp;
        <span
          className='reviews'
          onClick={() => {
            navigate('/reviews');
          }}
          style={{ cursor: 'pointer' }}
        >
          (134 reviews)
        </span>
      </div>
    </section>
  );
};

export default ViewTourHeader;
