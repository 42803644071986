import React, { useEffect, useState, useMemo } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import '../../assets/css/bookingTour/reviewBooking.css';
import YourBooking from './YourBooking';
import { RiEdit2Line } from "react-icons/ri";
import Button from './Button';
import axios from 'axios';

const ReviewBooking = () => {
    const [countries, setCountries] = useState([]);
    const { id } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        const fetchCountries = async () => {
            try {
                const response = await axios.get('https://api.first.org/data/v1/countries');
                const data = response.data.data;
                setCountries(Object.keys(data).map(key => data[key]));
            } catch (error) {
                console.error("Error fetching countries:", error);
            }
        };
        fetchCountries();
    }, []);

    const handleConfirmPayment = () => {
        //other payment algorithm goes here
        navigate(`/tours/${id}/booking/payment-successful`)
    }



    const countryOptions = useMemo(() => {
        return countries.map(c => (
            <option key={c.country} value={c.country}>{c.country}</option>
        ));
    }, [countries]);

    return (
        <div className="review-booking-container">
            <div className="your-trip">
                <h2>Your Trip</h2>
                <div className="trip-details">
                    <h3>Paris to Rome: Picnics & Pizza</h3>
                    <div>
                        Dates:
                        <div>
                            <a href="#">October 03-07</a>
                            <span><RiEdit2Line /></span>
                        </div>
                    </div>
                </div>

                <h2>Traveler Details</h2>
                <div className="traveler-details">
                    <div>
                        John Doe
                        <div>
                            <span>Special request: I have allergies from peanuts</span>
                            <a href="#"><RiEdit2Line /></a>
                        </div>
                    </div>
                    <div>
                        <span>
                            Sarah Doe <a href="#"><RiEdit2Line /></a>
                        </span>
                    </div>
                </div>

                <h2>Choose how to pay</h2>
                <div className="payment-options">
                    <label>
                        <input type="checkbox" name="payment-method" value="card" defaultChecked />
                        Credit/Debit card
                    </label>
                    <label>
                        <input type="checkbox" name="payment-method" value="apple-pay" />
                        Apple Pay
                    </label>
                    <label>
                        <input type="checkbox" name="payment-method" value="paypal" />
                        PayPal
                    </label>
                </div>

                <div className="payment-form">
                    <input type="text" placeholder="Card number" required />
                    <div className="row">
                        <input type="text" placeholder="Expiration" required />
                        <input type="text" placeholder="CVV" required />
                    </div>
                </div>

                <div className="billing-address">
                    <h2>Billing address</h2>
                    <input type="text" placeholder="Street address" required />
                    <input type="text" placeholder="Apt or suite number" />
                    <input type="text" placeholder="City" required />
                    <div className="row">
                        <input type="text" placeholder="State" required />
                        <input type="text" placeholder="Zip code" required />
                    </div>
                    <select required>
                        <option value="-" disabled selected>Country</option>
                        {countryOptions}
                    </select>
                </div>

                <div className="terms">
                    <input type="checkbox" required />
                    <span>
                        I agree to all <a href="#">Terms & conditions</a>
                    </span>
                </div>

                <Button onClick={handleConfirmPayment}>Confirm payment</Button>
            </div>

            <YourBooking />
        </div>
    );
};

export default ReviewBooking;