import { combineReducers } from '@reduxjs/toolkit';

// ** Reducers Imports
import BookingsSlice from './bookings';
import GetInTouch from './getInTouch';
import auth from './authSlice';
import Account from './account';
import Tours from './home';

const rootReducer = combineReducers({
  BookingsSlice,
  GetInTouch,
  auth,
  Account,
  Tours
});

export default rootReducer;
