import React, { useEffect, useState } from 'react';
import { Outlet, useLocation, useParams } from 'react-router-dom'; // removed BrowserRouter
import '../../assets/css/bookingTour/booking.css';
import StepNavigation from './StepNavigation';
import ViewTourHeader from '../ViewTourHeader';

const BookingTour = () => {
  const [showNav, setShowNav] = useState(true);
  const location = useLocation();
  const { id } = useParams();
  useEffect(() => {
    setShowNav(location.pathname === `/tours/${id}/booking/payment-successful`);
  }, [location.pathname]);

  return (
    <div className='booking-tour-container'>
      {!showNav && (
        <>
          <ViewTourHeader />
          <StepNavigation />
        </>
      )}
      <Outlet />
    </div>
  );
};

export default BookingTour;
