import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
// const baseURL = process.env.REACT_APP_MTA_WEB_VIEW_BASE_URL_DEVELOPMENT;

// Async thunk for submitting the contact form
export const submitContactForm = createAsyncThunk(
    "GetInTouch/submitContactForm",
    async (formData, { rejectWithValue }) => {
        try {
            const response = await axios.post(
                "http://localhost:4000/api/contact-us",
                formData
            );
            return response.data;
        } catch (error) {
            // Return error with rejectWithValue to handle in the rejected case
            return rejectWithValue(
                error.response?.data?.message || error.message
            );
        }
    }
);

export const GetInTouch = createSlice({
    name: "GetInTouch",
    initialState: {
        formSubmissionSuccess: null,
        formErrorMessage: null,
        isFormLoading: false
    },
    reducers: {
        updateStateValue: (state, action) => {
            state.isFormLoading = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(submitContactForm.pending, (state) => {
                state.isFormLoading = true;
                state.formSubmissionSuccess = null;
                state.formErrorMessage = null;
            })
            .addCase(submitContactForm.fulfilled, (state, action) => {
                state.isFormLoading = false;
                state.formSubmissionSuccess = action.payload.message;
            })
            .addCase(submitContactForm.rejected, (state, action) => {
                state.isFormLoading = false;
                state.formErrorMessage = action.payload;
            });
    },

});

export const {
    updateStateValue
} = GetInTouch.actions;

export default GetInTouch.reducer;
