import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

// Async thunk to fetch user details
export const fetchUserDetails = createAsyncThunk(
  'Account/fetchUserDetails',
  async (_, { getState, rejectWithValue }) => {
    const token = getState().auth.token;
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await axios.get(
        'http://localhost:4000/api/users/me',
        config
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.message || 'Failed to fetch user details'
      );
    }
  }
);

// Async thunk to update user details
export const updateUserDetails = createAsyncThunk(
  'Account/updateUserDetails',
  async (userDetails, { getState, rejectWithValue }) => {
    const token = getState().auth.token;
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await axios.put(
        'http://localhost:4000/api/users/me',
        userDetails,
        config
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.message || 'Failed to update user details'
      );
    }
  }
);

// Async thunk to handle updating password
export const updatePassword = createAsyncThunk(
  'Account/updatePassword',
  async (formData, { getState, rejectWithValue }) => {
    const token = getState().auth.token;
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      const response = await axios.put(
        'http://localhost:4000/api/users/update-password',
        formData,
        config
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.message || 'Update password failed!'
      );
    }
  }
);

export const accountSlice = createSlice({
  name: 'Account',
  initialState: {
    isFormLoading: false,
    userDetails: null,
    error: null,
  },
  reducers: {
    updateStateValue: (state, action) => {
      state.isFormLoading = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserDetails.pending, (state) => {
        state.isFormLoading = true;
        state.error = null;
      })
      .addCase(fetchUserDetails.fulfilled, (state, action) => {
        state.isFormLoading = false;
        state.userDetails = action.payload;
      })
      .addCase(fetchUserDetails.rejected, (state, action) => {
        state.isFormLoading = false;
        state.error = action.payload;
      })
      .addCase(updateUserDetails.pending, (state) => {
        state.isFormLoading = true;
        state.error = null;
      })
      .addCase(updateUserDetails.fulfilled, (state, action) => {
        state.isFormLoading = false;
        state.userDetails = action.payload;
      })
      .addCase(updateUserDetails.rejected, (state, action) => {
        state.isFormLoading = false;
        state.error = action.payload;
      })
      .addCase(updatePassword.pending, (state) => {
        state.isFormLoading = true;
        state.error = null;
      })
      .addCase(updatePassword.fulfilled, (state, action) => {
        state.isFormLoading = false;
        state.userDetails = action.payload;
      })
      .addCase(updatePassword.rejected, (state, action) => {
        state.isFormLoading = false;
        state.error = action.payload;
      });
  },
});

export const { updateStateValue } = accountSlice.actions;

export default accountSlice.reducer;
