import React, { useState } from 'react';
import '../assets/css/Navbar.css';
import logo from '../assets/images/logo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBookmark } from '@fortawesome/free-solid-svg-icons';
import { faPhoneVolume } from '@fortawesome/free-solid-svg-icons';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { logout } from '../redux/authSlice';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { clearSearchResults, searchTours } from '../redux/home';

const Navbar = () => {
  const apikey = process.env.REACT_APP_API_KEY;
  const regions = [
    'Antarctica',
    'Asia',
    'Australia',
    'Central America',
    'South America',
    'North America',
    'Middle East',
    'Europe',
  ];
  const [isOpen, setIsOpen] = useState(false);
  const [destinations, setDestinations] = useState(false);
  const location = useLocation();
  const isHomePage =
    location.pathname === '/' ||
    location.pathname === '/faqs' ||
    location.pathname === '/get-in-touch' ||
    location.pathname === '/blogs' ||
    location.pathname === '/reviews' ||
    location.pathname === '/privacy-policy' ||
    location.pathname === '/about-us';

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const navigate = useNavigate();
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const dispatch = useDispatch();

  const handleSignOut = () => {
    dispatch(logout());
    navigate('/');
  };

  const handleRegionClick = (region) => {
    navigate(`/tours?name=${region}`); // Ensure correct variable is used for URL

    if (region?.trim() === '') {
      dispatch(clearSearchResults()); // Reset search results to all tours
    } else {
      // Make sure the correct values are passed
      dispatch(searchTours({ inputValue: region, apikey }))
        .unwrap()
        .then((result) => {
          if (result.length > 0) {
            // Navigate if there are results
            // navigate(`/tours?name=${region}`); // Ensure correct variable is used for URL
          } else {
            console.log('No results found');
          }
        })
        .catch((error) => {
          console.error('Error during search:', error);
        });
    }
  };
  return (
    <nav
      className={`navbar ${isOpen && 'p-0'} || ${
        isHomePage ? 'home' : 'other'
      }`}
    >
      {isOpen ? (
        <>
          <div className='mobile-header bg-[#ffffff] w-full h-[100vh] z-10'>
            <div className='top flex items-center justify-between px-3'>
              <Link className='cursor-pointer' to='/'>
                <img src={logo} width={'50px'} alt='logo' />
              </Link>
              <svg
                className='cursor-pointer'
                onClick={toggleMenu}
                width='43'
                height='41'
                viewBox='0 0 43 41'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <rect
                  y='0.255859'
                  width='43'
                  height='40'
                  rx='8'
                  fill='#F8F8F8'
                />
                <path
                  d='M26.75 25.5059L16.25 15.0059M26.75 15.0059L16.25 25.5059'
                  stroke='#ABABAB'
                  stroke-width='2'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                />
              </svg>
            </div>
            <hr className='m-0 border-1 border-[rgba(0,0,0,0.05)]' />

            <ul className='list-none px-3 mt-5'>
              <li className='flex items-center justify-between'>
                <p
                  className='cursor-pointer text-[#1E1E1E] text-[16px] font-[600] m-0'
                  onClick={() => setDestinations(!destinations)}
                >
                  Destinations
                </p>
                {destinations ? (
                  <svg
                    className='cursor-pointer'
                    onClick={() => setDestinations(!destinations)}
                    width='20'
                    height='21'
                    viewBox='0 0 20 21'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M15.8327 7.79785L9.99935 13.6312L4.16602 7.79785'
                      stroke='#1E1E1E'
                      stroke-width='1.5'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                    />
                  </svg>
                ) : (
                  <svg
                    className='cursor-pointer mt-1'
                    onClick={() => setDestinations(!destinations)}
                    width='20'
                    height='21'
                    viewBox='0 0 20 21'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M5.66797 3.83415L10.3346 8.50081L5.66797 13.1675'
                      stroke='#1E1E1E'
                      stroke-width='1.5'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                    />
                  </svg>
                )}
              </li>
              {destinations && (
                <ul className='p-0'>
                  {regions.map((region, index) => (
                    <p
                      onClick={() => handleRegionClick(region)}
                      id={index}
                      className='cursor-pointer text-[#1E1E1E] text-[16px] font-[500]'
                    >
                      {region}
                    </p>
                  ))}
                </ul>
              )}
              <li className='cursor-pointer text-[#1E1E1E] text-[16px] font-[600] mt-4 mb-4'>
                Why Carpedm
              </li>
              <li
                onClick={() => navigate('/blogs')}
                className='cursor-pointer text-[#1E1E1E] text-[16px] font-[600]'
              >
                Blog
              </li>
            </ul>
            <hr className='border-1 border-[rgba(0,0,0,0.05)]' />
            <div className='flex items-center justify-center gap-4 mt-5'>
              <svg
                onClick={() => navigate('/get-in-touch')}
                width='24'
                height='25'
                viewBox='0 0 24 25'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M21.7038 11.2538C21.3268 11.2538 21.0028 10.9708 20.9598 10.5878C20.5798 7.21182 17.9568 4.59182 14.5808 4.21682C14.1698 4.17082 13.8728 3.80082 13.9188 3.38882C13.9638 2.97782 14.3328 2.67082 14.7468 2.72682C18.8238 3.17882 21.9918 6.34282 22.4498 10.4198C22.4968 10.8318 22.1998 11.2028 21.7888 11.2488C21.7608 11.2518 21.7318 11.2538 21.7038 11.2538Z'
                  fill='#019BBB'
                />
                <path
                  d='M18.163 11.2646C17.811 11.2646 17.498 11.0166 17.428 10.6586C17.14 9.17858 15.999 8.03758 14.521 7.75058C14.114 7.67158 13.849 7.27858 13.928 6.87158C14.007 6.46458 14.41 6.19958 14.807 6.27858C16.888 6.68258 18.495 8.28858 18.9 10.3706C18.979 10.7786 18.714 11.1716 18.308 11.2506C18.259 11.2596 18.211 11.2646 18.163 11.2646Z'
                  fill='#019BBB'
                />
                <path
                  fill-rule='evenodd'
                  clip-rule='evenodd'
                  d='M7.48166 17.5507C12.1947 22.2647 15.3327 23.5007 17.3147 23.5007C18.2927 23.5007 18.9907 23.1997 19.4557 22.8657C19.4767 22.8537 21.6287 21.5377 22.0057 19.5457C22.1837 18.6107 21.9237 17.6857 21.2557 16.8697C18.5037 13.5287 17.1017 13.8407 15.5537 14.5937C14.6027 15.0597 13.8517 15.4217 11.7307 13.3027C9.61091 11.182 9.97658 10.4309 10.4395 9.48003C11.1935 7.93203 11.5037 6.5297 8.16166 3.7757C7.34766 3.1107 6.42866 2.8507 5.49466 3.0257C3.53166 3.3927 2.20766 5.5097 2.20966 5.5097C1.15766 6.9867 0.443667 10.5137 7.48166 17.5507ZM5.80066 4.4937C5.88866 4.4797 5.97566 4.4717 6.06166 4.4717C6.45366 4.4717 6.83166 4.6247 7.20866 4.9347C9.90364 7.15469 9.56267 7.85469 9.09067 8.82367C8.38167 10.2807 8.01066 11.7027 10.6697 14.3637C13.3317 17.0247 14.7547 16.6537 16.2097 15.9427L16.2121 15.9415C17.1799 15.4711 17.8796 15.131 20.0967 17.8227C20.4757 18.2847 20.6207 18.7467 20.5387 19.2337C20.3497 20.3537 19.0477 21.3567 18.6537 21.6007C17.2427 22.6067 13.8457 21.7937 8.54166 16.4907C3.23966 11.1877 2.42566 7.7907 3.46766 6.3257C3.67566 5.9867 4.68266 4.6827 5.80066 4.4937Z'
                  fill='#019BBB'
                />
              </svg>

              <svg
                onClick={() => navigate('/account')}
                width='24'
                height='25'
                viewBox='0 0 24 25'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  fill-rule='evenodd'
                  clip-rule='evenodd'
                  d='M11.9849 16.0605C8.11731 16.0605 4.81445 16.6453 4.81445 18.9872C4.81445 21.3291 8.09636 21.9348 11.9849 21.9348C15.8525 21.9348 19.1545 21.3491 19.1545 19.0082C19.1545 16.6672 15.8735 16.0605 11.9849 16.0605Z'
                  stroke='#019BBB'
                  stroke-width='1.5'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                />
                <path
                  fill-rule='evenodd'
                  clip-rule='evenodd'
                  d='M11.9849 12.7202C14.523 12.7202 16.5802 10.6621 16.5802 8.12405C16.5802 5.58595 14.523 3.52881 11.9849 3.52881C9.44682 3.52881 7.3887 5.58595 7.3887 8.12405C7.38015 10.6536 9.42396 12.7117 11.9525 12.7202H11.9849Z'
                  stroke='#019BBB'
                  stroke-width='1.42857'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                />
              </svg>
            </div>
          </div>
        </>
      ) : (
        <>
          <a href='/'>
            <img src={logo} width={'100px'} alt='logo' />
          </a>
          <div className={`nav-titles ${isOpen ? 'open' : ''}`}>
            <div className='desti'>
              <div className='flex items-center'>
                <p
                  className='mr-2 cursor-pointer'
                  onClick={() => setDestinations(!destinations)}
                >
                  Destinations
                </p>
                {destinations ? (
                  <>
                    {isHomePage ? (
                      <svg
                        className='cursor-pointer'
                        onClick={() => setDestinations(!destinations)}
                        width='20'
                        height='21'
                        viewBox='0 0 20 21'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M15.8327 7.79785L9.99935 13.6312L4.16602 7.79785'
                          stroke='#ffffff'
                          stroke-width='1.5'
                          stroke-linecap='round'
                          stroke-linejoin='round'
                        />
                      </svg>
                    ) : (
                      <svg
                        className='cursor-pointer'
                        onClick={() => setDestinations(!destinations)}
                        width='20'
                        height='21'
                        viewBox='0 0 20 21'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M15.8327 7.79785L9.99935 13.6312L4.16602 7.79785'
                          stroke='#000'
                          stroke-width='1.5'
                          stroke-linecap='round'
                          stroke-linejoin='round'
                        />
                      </svg>
                    )}
                  </>
                ) : (
                  <>
                    {isHomePage ? (
                      <svg
                        className='cursor-pointer mt-1'
                        onClick={() => setDestinations(!destinations)}
                        width='20'
                        height='21'
                        viewBox='0 0 20 21'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M5.66797 3.83415L10.3346 8.50081L5.66797 13.1675'
                          stroke='#ffffff'
                          stroke-width='1.5'
                          stroke-linecap='round'
                          stroke-linejoin='round'
                        />
                      </svg>
                    ) : (
                      <svg
                        className='cursor-pointer mt-1'
                        onClick={() => setDestinations(!destinations)}
                        width='20'
                        height='21'
                        viewBox='0 0 20 21'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M5.66797 3.83415L10.3346 8.50081L5.66797 13.1675'
                          stroke='#000'
                          stroke-width='1.5'
                          stroke-linecap='round'
                          stroke-linejoin='round'
                        />
                      </svg>
                    )}
                  </>
                )}

                {destinations && (
                  <ul className='absolute top-12 list-none bg-[#ffffff] text-[#434343] pt-3 pb-3 px-3 rounded-lg z-10'>
                    {regions &&
                      regions.map((region, index) => (
                        <React.Fragment key={index}>
                          <li
                            onClick={() => handleRegionClick(region)}
                            className='cursor-pointer flex items-center justify-between w-40'
                          >
                            <span>{region}</span>
                            <svg
                              width='16'
                              height='17'
                              viewBox='0 0 16 17'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <path
                                d='M5.66797 3.83415L10.3346 8.50081L5.66797 13.1675'
                                stroke='#1F1F1F'
                                strokeWidth='1.2'
                                strokeLinecap='round'
                                strokeLinejoin='round'
                              />
                            </svg>
                          </li>
                          {index < regions.length - 1 && (
                            <hr className='border-1 border-[rgba(0,0,0,0.05)]' />
                          )}
                        </React.Fragment>
                      ))}
                  </ul>
                )}
              </div>
            </div>
            <p className='cursor-pointer'>Why Carpedm?</p>
            <p className='cursor-pointer' onClick={() => navigate('/blogs')}>
              Blogs
            </p>
            <div className='nav-icons open'>
              <svg
                onClick={() => navigate('/blogs')}
                width='24'
                height='25'
                viewBox='0 0 24 25'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M21.7038 11.2538C21.3268 11.2538 21.0028 10.9708 20.9598 10.5878C20.5798 7.21182 17.9568 4.59182 14.5808 4.21682C14.1698 4.17082 13.8728 3.80082 13.9188 3.38882C13.9638 2.97782 14.3328 2.67082 14.7468 2.72682C18.8238 3.17882 21.9918 6.34282 22.4498 10.4198C22.4968 10.8318 22.1998 11.2028 21.7888 11.2488C21.7608 11.2518 21.7318 11.2538 21.7038 11.2538Z'
                  fill='#019BBB'
                />
                <path
                  d='M18.163 11.2646C17.811 11.2646 17.498 11.0166 17.428 10.6586C17.14 9.17858 15.999 8.03758 14.521 7.75058C14.114 7.67158 13.849 7.27858 13.928 6.87158C14.007 6.46458 14.41 6.19958 14.807 6.27858C16.888 6.68258 18.495 8.28858 18.9 10.3706C18.979 10.7786 18.714 11.1716 18.308 11.2506C18.259 11.2596 18.211 11.2646 18.163 11.2646Z'
                  fill='#019BBB'
                />
                <path
                  fill-rule='evenodd'
                  clip-rule='evenodd'
                  d='M7.48166 17.5507C12.1947 22.2647 15.3327 23.5007 17.3147 23.5007C18.2927 23.5007 18.9907 23.1997 19.4557 22.8657C19.4767 22.8537 21.6287 21.5377 22.0057 19.5457C22.1837 18.6107 21.9237 17.6857 21.2557 16.8697C18.5037 13.5287 17.1017 13.8407 15.5537 14.5937C14.6027 15.0597 13.8517 15.4217 11.7307 13.3027C9.61091 11.182 9.97658 10.4309 10.4395 9.48003C11.1935 7.93203 11.5037 6.5297 8.16166 3.7757C7.34766 3.1107 6.42866 2.8507 5.49466 3.0257C3.53166 3.3927 2.20766 5.5097 2.20966 5.5097C1.15766 6.9867 0.443667 10.5137 7.48166 17.5507ZM5.80066 4.4937C5.88866 4.4797 5.97566 4.4717 6.06166 4.4717C6.45366 4.4717 6.83166 4.6247 7.20866 4.9347C9.90364 7.15469 9.56267 7.85469 9.09067 8.82367C8.38167 10.2807 8.01066 11.7027 10.6697 14.3637C13.3317 17.0247 14.7547 16.6537 16.2097 15.9427L16.2121 15.9415C17.1799 15.4711 17.8796 15.131 20.0967 17.8227C20.4757 18.2847 20.6207 18.7467 20.5387 19.2337C20.3497 20.3537 19.0477 21.3567 18.6537 21.6007C17.2427 22.6067 13.8457 21.7937 8.54166 16.4907C3.23966 11.1877 2.42566 7.7907 3.46766 6.3257C3.67566 5.9867 4.68266 4.6827 5.80066 4.4937Z'
                  fill='#019BBB'
                />
              </svg>

              <svg
                width='24'
                height='25'
                viewBox='0 0 24 25'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  fill-rule='evenodd'
                  clip-rule='evenodd'
                  d='M11.9849 16.0605C8.11731 16.0605 4.81445 16.6453 4.81445 18.9872C4.81445 21.3291 8.09636 21.9348 11.9849 21.9348C15.8525 21.9348 19.1545 21.3491 19.1545 19.0082C19.1545 16.6672 15.8735 16.0605 11.9849 16.0605Z'
                  stroke='#019BBB'
                  stroke-width='1.5'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                />
                <path
                  fill-rule='evenodd'
                  clip-rule='evenodd'
                  d='M11.9849 12.7202C14.523 12.7202 16.5802 10.6621 16.5802 8.12405C16.5802 5.58595 14.523 3.52881 11.9849 3.52881C9.44682 3.52881 7.3887 5.58595 7.3887 8.12405C7.38015 10.6536 9.42396 12.7117 11.9525 12.7202H11.9849Z'
                  stroke='#019BBB'
                  stroke-width='1.42857'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                />
              </svg>
            </div>
          </div>
          <div className='nav-icons'>
            {isHomePage ? (
              <>
                {' '}
                <svg
                  className='cursor-pointer'
                  onClick={() => navigate('/get-in-touch')}
                  width='24'
                  height='25'
                  viewBox='0 0 24 25'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M21.7038 11.2538C21.3268 11.2538 21.0028 10.9708 20.9598 10.5878C20.5798 7.21182 17.9568 4.59182 14.5808 4.21682C14.1698 4.17082 13.8728 3.80082 13.9188 3.38882C13.9638 2.97782 14.3328 2.67082 14.7468 2.72682C18.8238 3.17882 21.9918 6.34282 22.4498 10.4198C22.4968 10.8318 22.1998 11.2028 21.7888 11.2488C21.7608 11.2518 21.7318 11.2538 21.7038 11.2538Z'
                    fill='#ffffff'
                  />
                  <path
                    d='M18.163 11.2646C17.811 11.2646 17.498 11.0166 17.428 10.6586C17.14 9.17858 15.999 8.03758 14.521 7.75058C14.114 7.67158 13.849 7.27858 13.928 6.87158C14.007 6.46458 14.41 6.19958 14.807 6.27858C16.888 6.68258 18.495 8.28858 18.9 10.3706C18.979 10.7786 18.714 11.1716 18.308 11.2506C18.259 11.2596 18.211 11.2646 18.163 11.2646Z'
                    fill='#ffffff'
                  />
                  <path
                    fill-rule='evenodd'
                    clip-rule='evenodd'
                    d='M7.48166 17.5507C12.1947 22.2647 15.3327 23.5007 17.3147 23.5007C18.2927 23.5007 18.9907 23.1997 19.4557 22.8657C19.4767 22.8537 21.6287 21.5377 22.0057 19.5457C22.1837 18.6107 21.9237 17.6857 21.2557 16.8697C18.5037 13.5287 17.1017 13.8407 15.5537 14.5937C14.6027 15.0597 13.8517 15.4217 11.7307 13.3027C9.61091 11.182 9.97658 10.4309 10.4395 9.48003C11.1935 7.93203 11.5037 6.5297 8.16166 3.7757C7.34766 3.1107 6.42866 2.8507 5.49466 3.0257C3.53166 3.3927 2.20766 5.5097 2.20966 5.5097C1.15766 6.9867 0.443667 10.5137 7.48166 17.5507ZM5.80066 4.4937C5.88866 4.4797 5.97566 4.4717 6.06166 4.4717C6.45366 4.4717 6.83166 4.6247 7.20866 4.9347C9.90364 7.15469 9.56267 7.85469 9.09067 8.82367C8.38167 10.2807 8.01066 11.7027 10.6697 14.3637C13.3317 17.0247 14.7547 16.6537 16.2097 15.9427L16.2121 15.9415C17.1799 15.4711 17.8796 15.131 20.0967 17.8227C20.4757 18.2847 20.6207 18.7467 20.5387 19.2337C20.3497 20.3537 19.0477 21.3567 18.6537 21.6007C17.2427 22.6067 13.8457 21.7937 8.54166 16.4907C3.23966 11.1877 2.42566 7.7907 3.46766 6.3257C3.67566 5.9867 4.68266 4.6827 5.80066 4.4937Z'
                    fill='#ffffff'
                  />
                </svg>
                <svg
                  className='cursor-pointer'
                  onClick={() => navigate('/login')}
                  width='24'
                  height='25'
                  viewBox='0 0 24 25'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    fill-rule='evenodd'
                    clip-rule='evenodd'
                    d='M11.9849 16.0605C8.11731 16.0605 4.81445 16.6453 4.81445 18.9872C4.81445 21.3291 8.09636 21.9348 11.9849 21.9348C15.8525 21.9348 19.1545 21.3491 19.1545 19.0082C19.1545 16.6672 15.8735 16.0605 11.9849 16.0605Z'
                    stroke='#ffffff'
                    stroke-width='1.5'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                  />
                  <path
                    fill-rule='evenodd'
                    clip-rule='evenodd'
                    d='M11.9849 12.7202C14.523 12.7202 16.5802 10.6621 16.5802 8.12405C16.5802 5.58595 14.523 3.52881 11.9849 3.52881C9.44682 3.52881 7.3887 5.58595 7.3887 8.12405C7.38015 10.6536 9.42396 12.7117 11.9525 12.7202H11.9849Z'
                    stroke='#ffffff'
                    stroke-width='1.42857'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                  />
                </svg>
              </>
            ) : (
              <>
                {' '}
                <svg
                  className='cursor-pointer'
                  onClick={() => navigate('/get-in-touch')}
                  width='24'
                  height='25'
                  viewBox='0 0 24 25'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M21.7038 11.2538C21.3268 11.2538 21.0028 10.9708 20.9598 10.5878C20.5798 7.21182 17.9568 4.59182 14.5808 4.21682C14.1698 4.17082 13.8728 3.80082 13.9188 3.38882C13.9638 2.97782 14.3328 2.67082 14.7468 2.72682C18.8238 3.17882 21.9918 6.34282 22.4498 10.4198C22.4968 10.8318 22.1998 11.2028 21.7888 11.2488C21.7608 11.2518 21.7318 11.2538 21.7038 11.2538Z'
                    fill='#000'
                  />
                  <path
                    d='M18.163 11.2646C17.811 11.2646 17.498 11.0166 17.428 10.6586C17.14 9.17858 15.999 8.03758 14.521 7.75058C14.114 7.67158 13.849 7.27858 13.928 6.87158C14.007 6.46458 14.41 6.19958 14.807 6.27858C16.888 6.68258 18.495 8.28858 18.9 10.3706C18.979 10.7786 18.714 11.1716 18.308 11.2506C18.259 11.2596 18.211 11.2646 18.163 11.2646Z'
                    fill='#000'
                  />
                  <path
                    fill-rule='evenodd'
                    clip-rule='evenodd'
                    d='M7.48166 17.5507C12.1947 22.2647 15.3327 23.5007 17.3147 23.5007C18.2927 23.5007 18.9907 23.1997 19.4557 22.8657C19.4767 22.8537 21.6287 21.5377 22.0057 19.5457C22.1837 18.6107 21.9237 17.6857 21.2557 16.8697C18.5037 13.5287 17.1017 13.8407 15.5537 14.5937C14.6027 15.0597 13.8517 15.4217 11.7307 13.3027C9.61091 11.182 9.97658 10.4309 10.4395 9.48003C11.1935 7.93203 11.5037 6.5297 8.16166 3.7757C7.34766 3.1107 6.42866 2.8507 5.49466 3.0257C3.53166 3.3927 2.20766 5.5097 2.20966 5.5097C1.15766 6.9867 0.443667 10.5137 7.48166 17.5507ZM5.80066 4.4937C5.88866 4.4797 5.97566 4.4717 6.06166 4.4717C6.45366 4.4717 6.83166 4.6247 7.20866 4.9347C9.90364 7.15469 9.56267 7.85469 9.09067 8.82367C8.38167 10.2807 8.01066 11.7027 10.6697 14.3637C13.3317 17.0247 14.7547 16.6537 16.2097 15.9427L16.2121 15.9415C17.1799 15.4711 17.8796 15.131 20.0967 17.8227C20.4757 18.2847 20.6207 18.7467 20.5387 19.2337C20.3497 20.3537 19.0477 21.3567 18.6537 21.6007C17.2427 22.6067 13.8457 21.7937 8.54166 16.4907C3.23966 11.1877 2.42566 7.7907 3.46766 6.3257C3.67566 5.9867 4.68266 4.6827 5.80066 4.4937Z'
                    fill='#000'
                  />
                </svg>
                <svg
                  className='cursor-pointer'
                  onClick={() => navigate('/login')}
                  width='24'
                  height='25'
                  viewBox='0 0 24 25'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    fill-rule='evenodd'
                    clip-rule='evenodd'
                    d='M11.9849 16.0605C8.11731 16.0605 4.81445 16.6453 4.81445 18.9872C4.81445 21.3291 8.09636 21.9348 11.9849 21.9348C15.8525 21.9348 19.1545 21.3491 19.1545 19.0082C19.1545 16.6672 15.8735 16.0605 11.9849 16.0605Z'
                    stroke='#000'
                    stroke-width='1.5'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                  />
                  <path
                    fill-rule='evenodd'
                    clip-rule='evenodd'
                    d='M11.9849 12.7202C14.523 12.7202 16.5802 10.6621 16.5802 8.12405C16.5802 5.58595 14.523 3.52881 11.9849 3.52881C9.44682 3.52881 7.3887 5.58595 7.3887 8.12405C7.38015 10.6536 9.42396 12.7117 11.9525 12.7202H11.9849Z'
                    stroke='#000'
                    stroke-width='1.42857'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                  />
                </svg>
              </>
            )}
          </div>
        </>
      )}

      <div className='hamburger' onClick={toggleMenu}>
        {isOpen ? (
          <>
            {/* <svg width="43" height="41" viewBox="0 0 43 41" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect y="0.255859" width="43" height="40" rx="8" fill="#F8F8F8" />
            <path d="M26.75 25.5059L16.25 15.0059M26.75 15.0059L16.25 25.5059" stroke="#ABABAB" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
          </svg> */}
          </>
        ) : (
          <>
            {isHomePage ? (
              <>
                <svg
                  width='43'
                  height='40'
                  viewBox='0 0 43 40'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <rect
                    width='43'
                    height='40'
                    rx='8'
                    fill='white'
                    fill-opacity='0.3'
                  />
                  <path
                    d='M10 12.7447H33M10 20.2447H33M10 27.7447H33'
                    stroke='white'
                    stroke-width='2'
                    stroke-miterlimit='10'
                    stroke-linecap='round'
                  />
                </svg>
              </>
            ) : (
              <>
                <svg
                  width='43'
                  height='40'
                  viewBox='0 0 43 40'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <rect
                    width='43'
                    height='40'
                    rx='8'
                    fill='black'
                    fill-opacity='0.3'
                  />
                  <path
                    d='M10 12.7447H33M10 20.2447H33M10 27.7447H33'
                    stroke='black'
                    stroke-width='2'
                    stroke-miterlimit='10'
                    stroke-linecap='round'
                  />
                </svg>
              </>
            )}
          </>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
