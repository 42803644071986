import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// Async thunk for fetching all tours
export const fetchAllTours = createAsyncThunk(
    "Tours/fetchAllTours",
    async ({ apikey, page = 1 }, { rejectWithValue }) => {
        console.log("apikey, page:::::::", apikey, page)
        try {
            const response = await axios.get(
                `https://rest.gadventures.com/tour_dossiers?advertised_departures__notnull=1&max_per_page=24&page=${page}`,
                {
                    headers: {
                        'X-Application-Key': apikey,
                        Accept: 'application/json',
                        'Accept-Language': 'en',
                        // Host: 'rest.gadventures.com',
                    },
                }
            );
            return response.data.results; // Only return the results array
        } catch (error) {
            return rejectWithValue(
                error.response?.data?.message || error.message
            );
        }
    }
);

// Async thunk for handling search based on user input
export const searchTours = createAsyncThunk(
    "Tours/searchTours",
    async ({ inputValue, apikey, page = 1 }, { rejectWithValue }) => {
        try {
            const response = await axios.get(
                `https://rest.gadventures.com/tour_dossiers?name=${inputValue}&advertised_departures__notnull=1&max_per_page=24&page=${page}`,
                {
                    headers: {
                        'X-Application-Key': apikey,
                        Accept: 'application/json',
                        'Accept-Language': 'en',
                        Host: 'rest.gadventures.com',
                    },
                }
            );
            return response.data.results;
        } catch (error) {
            return rejectWithValue(
                error.response?.data?.message || error.message
            );
        }
    }
);


// Async thunk for fetching tour details
export const fetchTourDetails = createAsyncThunk(
    "Tours/fetchTourDetails",
    async ({ searchResults, apikey }, { rejectWithValue }) => {
        try {
            const details = {};

            // Use Promise.all to fetch details for all tours concurrently
            await Promise.all(
                searchResults.map(async (tour) => {
                    const response = await axios.get(
                        `https://rest.gadventures.com/tour_dossiers/${tour.id}`,
                        {
                            headers: {
                                'X-Application-Key': apikey,
                                Accept: 'application/json',
                                'Accept-Language': 'en',
                            },
                        }
                    );
                    details[tour.id] = response.data;
                    // Return the tour details to be merged with allTours later
                    // return {
                    //     ...tour, // Keep the existing tour data
                    //     tourDetails: response.data, // Add tourDetails property
                    // };
                })
            );

            return details;
        } catch (error) {
            return rejectWithValue(
                error.response?.data?.message || error.message
            );
        }
    }
);

// Async thunk for fetching tour details
// export const fetchTourDetails = createAsyncThunk(
//     "Tours/fetchTourDetails",
//     async ({ searchResults, apikey }, { rejectWithValue }) => {
//         try {
//             const updatedTours = await Promise.all(
//                 searchResults.map(async (tour) => {
//                     const response = await axios.get(
//                         `https://rest.gadventures.com/tour_dossiers/${tour.id}`,
//                         {
//                             headers: {
//                                 'X-Application-Key': apikey,
//                                 Accept: 'application/json',
//                                 'Accept-Language': 'en',
//                             },
//                         }
//                     );

//                     // Return the tour details to be merged with allTours later
//                     return {
//                         ...tour, // Keep the existing tour data
//                         tourDetails: response.data, // Add tourDetails property
//                     };
//                 })
//             );

//             return updatedTours; // Return the array of updated tours
//         } catch (error) {
//             return rejectWithValue(
//                 error.response?.data?.message || error.message
//             );
//         }
//     }
// );

// Async thunk for fetching departure details
export const fetchDepartureDetails = createAsyncThunk(
    "Tours/fetchDepartureDetails",
    async ({ searchResults, apikey }, { rejectWithValue }) => {
        try {
            const departureDetails = {};

            // Use Promise.all to fetch details for all departures concurrently
            await Promise.all(
                searchResults.flatMap((tour) =>
                    tour.advertised_departures.map(async (departure) => {
                        const response = await axios.get(
                            departure.href, // Use the href from the departure object
                            {
                                headers: {
                                    'X-Application-Key': apikey,
                                    Accept: 'application/json',
                                    'Accept-Language': 'en',
                                },
                            }
                        );
                        // Store the fetched details in departureDetails using the tour ID and departure href
                        departureDetails[departure.id] = response.data;
                    })
                )
            );

            return departureDetails; // Return the collected departure details
        } catch (error) {
            return rejectWithValue(
                error.response?.data?.message || error.message
            );
        }
    }
);


// Async thunk for fetching details of a single tour
export const fetchSingleTourDetails = createAsyncThunk(
    "Tours/fetchSingleTourDetails",
    async ({ id, apiKey }, { rejectWithValue }) => {
        try {
            const response = await axios.get(
                `https://rest.gadventures.com/tour_dossiers/${id}`,
                {
                    headers: {
                        'X-Application-Key': apiKey,
                    },
                }
            );
            return response.data;
        } catch (error) {
            return rejectWithValue(
                error.response?.data?.message || error.message
            );
        }
    }
);

// Async thunk for fetching conversion rates
export const fetchConversionRates = createAsyncThunk(
    "Tours/fetchConversionRates",
    async ({ ratesAPIKey }, { rejectWithValue }) => {
        try {
            const response = await axios.get(`https://v6.exchangerate-api.com/v6/${ratesAPIKey}/latest/USD`);
            return response.data.conversion_rates;
        } catch (error) {
            return rejectWithValue(error.response?.data?.message || error.message);
        }
    }
);


export const toursSlice = createSlice({
    name: "Tours",
    initialState: {
        allTours: [],
        searchResults: [],
        tourDetails: {},
        tourDeparturesDetails: {},
        conversionRates: {},
        singleTourDetails: null,
        isLoading: false,
        error: null,
    },
    reducers: {
        clearSearchResults: (state) => {
            state.searchResults = state.allTours;
        },
        clearSingleTourDetails: (state) => {
            state.singleTourDetails = null; // Reset single tour details
        },

        // Reducer to update USD amounts after conversion rates are fetched
        updateUsdAmount: (state) => {
            state.allTours = state.allTours.map((tour) => {
                tour.advertised_departures = tour.advertised_departures.map((departure) => {
                    const currency = departure.currency;
                    const conversionRate = state.conversionRates[currency] || 1; // Default to 1 if USD
                    departure.usdAmount = departure.amount / conversionRate;
                    return departure;
                });
                return tour;
            });
            state.searchResults = state.allTours; // Also update searchResults
        },
    },
    extraReducers: (builder) => {
        // Fetching all tours
        builder
            .addCase(fetchAllTours.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(fetchAllTours.fulfilled, (state, action) => {
                state.isLoading = false;
                state.allTours = action.payload;
                // if (state.allTours.length === 0) {
                //     // First API call, set the state with the fetched tours
                //     state.allTours = action.payload;
                // } else {
                //     // Append new tours when fetching more pages
                //     state.allTours = [...state.allTours, ...action.payload];
                // }
                state.searchResults = action.payload;
                // state.searchResults = state.allTours;
            })
            .addCase(fetchAllTours.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload;
            });

        // Searching tours
        builder
            .addCase(searchTours.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(searchTours.fulfilled, (state, action) => {
                state.isLoading = false;
                state.searchResults = action.payload;
            })
            .addCase(searchTours.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload;
            });

        // Fetch tour details
        builder
            .addCase(fetchTourDetails.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(fetchTourDetails.fulfilled, (state, action) => {
                state.isLoading = false;
                state.tourDetails = action.payload; // Store the fetched tour details

                // state.isLoading = false;
                // // Update allTours with the new tourDetails
                // state.allTours = state.allTours.map((tour) => {
                //     const updatedTour = action.payload.find(t => t.id === tour.id);
                //     return updatedTour ? updatedTour : tour; // Return updated tour or original if not found
                // });
            })
            .addCase(fetchTourDetails.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload;
            });

        // Fetch tour departures details
        builder
            .addCase(fetchDepartureDetails.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(fetchDepartureDetails.fulfilled, (state, action) => {
                state.isLoading = false;
                state.tourDeparturesDetails = action.payload; // Store the fetched tour details
            })
            .addCase(fetchDepartureDetails.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload;
            });

        // Fetch single tour details
        builder
            .addCase(fetchSingleTourDetails.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(fetchSingleTourDetails.fulfilled, (state, action) => {
                state.isLoading = false;
                state.singleTourDetails = action.payload; // Store fetched tour details
            })
            .addCase(fetchSingleTourDetails.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload;
            });

        // Fetching conversion rates
        builder
            .addCase(fetchConversionRates.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(fetchConversionRates.fulfilled, (state, action) => {
                state.isLoading = false;
                state.conversionRates = action.payload;

                // Loop through all tours and update usdAmount for each advertised_departure
                state.allTours = state.allTours.map((tour) => {
                    tour.advertised_departures = tour.advertised_departures.map((departure) => {
                        const currency = departure.currency;
                        const conversionRate = action.payload[currency] || 1; // Default to 1 if USD
                        departure.usdAmount = departure.amount / conversionRate; // Calculate usdAmount
                        return departure;
                    });
                    return tour;
                });

                state.searchResults = state.allTours; // Keep searchResults updated as well
            })
            .addCase(fetchConversionRates.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload;
            });
    },
});

export const { clearSearchResults, clearSingleTourDetails } = toursSlice.actions;

export default toursSlice.reducer;
