import React, { useState } from 'react';
import { FaCalendarAlt, FaFacebookF, FaGoogle, FaLinkedinIn } from 'react-icons/fa';
import { Link, useNavigate, useParams } from "react-router-dom";
import '../../assets/css/bookingTour/addTrverlers.css';
import Button from './Button';
import YourBooking from './YourBooking';

const AddTravelers = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const handleContinue = () => {
        navigate(`/tours/${id}/booking/review-booking`)
    }

    const [isChecked, setIsChecked] = useState(false);

    const handleCheckboxChange = () => {
        setIsChecked(!isChecked);
    };
    return (
        <div className="add-travelers-container">
            <div className="form-section">
                <h2>Buyer Information</h2>
                <h3> <Link to={'/login'}>Log In</Link> to continue </h3>
                <form className="buyer-form">
                    <div className="input-group">

                        <input type="text" placeholder="First Name*" />
                    </div>
                    <div className="input-group">

                        <input type="text" placeholder="Last Name*" />
                    </div>
                    <div className="input-group">
                        <input type="email" placeholder="Email*" />
                    </div>
                    <div className="input-group">
                        <input type="email" placeholder="Confirm Email*" />
                    </div>
                    <div className="checkbox-group">
                        <input type="checkbox" id="signup-checkbox" checked={isChecked} onChange={handleCheckboxChange} />
                        <label htmlFor="signup-checkbox">Sign up to manage your booking</label>
                    </div>
                    {isChecked && (
                        <>
                            <div className="input-group">
                                <input type="password" placeholder="Password" required />
                            </div>

                            <div className="social-media-container">
                                <p>Or create account with social media</p>
                                <div className="social-buttons">
                                    <button className="linkedin-btn">
                                        <FaLinkedinIn /> LinkedIn
                                    </button>
                                    <button className="facebook-btn">
                                        <FaFacebookF /> Facebook
                                    </button>
                                    <button className="google-btn">
                                        <FaGoogle /> Google
                                    </button>
                                </div>
                            </div>
                        </>
                    )}

                    <h2>Enter details of Traveler 1</h2>
                    <div className="input-group">

                        <input type="text" placeholder="First name*" />
                    </div>
                    <div className="input-group">

                        <input type="text" placeholder="Last name*" />
                    </div>
                    <div className="input-group">
                        <input type="email" placeholder="Email*" />
                    </div>
                    <div className="input-group">

                        <input type="text" placeholder="Passport number*" />
                    </div>
                    <div className="input-group">

                        <input type="text" placeholder="Nationality*" />
                    </div>
                    <div className="input-group date-input-group">

                        <input
                            type="text"
                            placeholder="Date of Birth"
                            onFocus={(e) => e.target.type = 'date'}
                            onBlur={(e) => e.target.type = 'text'}
                        />
                        {/* <FaCalendarAlt /> */}
                    </div>
                    <div className="input-group">
                        <input type="text" placeholder="Diet Restrictions/Allergies/Medical Conditions" />
                    </div>
                    <div className="input-group">
                        <input type="text" placeholder="Emergency contact" />
                    </div>
                    <div className="input-group">
                        <textarea placeholder="Notes to organizer" />
                    </div>
                    {/* Add more travelers as needed */}
                    <div className='Acknowledgement-risk'>
                        <h3>Acknowledgement of Risk</h3>
                        <p>At CarpeDM Adventures, your safety and satisfaction are our top<br />
                            <span className='disableText'> priorities. We strive to provide exceptional experiences and...</span>
                            <span className='readMore'>Read more</span>
                        </p>
                        <div className="checkbox-group">
                            <input type="checkbox" />
                            <label>I acknowledge</label>
                        </div>
                    </div>
                    <div>
                        <Button type='submit' className="add_traverler-conti" onClick={handleContinue}>Continue</Button>
                    </div>
                </form>
            </div >

            <YourBooking />
        </div >
    );
};

export default AddTravelers;
