import React from 'react';
import '../../assets/css/blogs/blogs.css';
import Navbar from '../Navbar';
import BlogCard from './BlogCard';
import Footer from '../Footer';

const ListofBlogs = () => {
  return (
    <main className='w-full min-h-screen'>
      <div
        className='w-full h-[300px] md:h-[600px] bg-cover bg-center '
        id='top-section'
      >
        <div>
          <Navbar />
          <div className='w-full h-[200px] md:h-[400px] flex flex-col justify-center items-center'>
            <h1 className='font-sans text-xl md:text-2xl font-bold mt-10 md:mt-20 text-white text-center'>
              CarpeDM Ecuador Blog
            </h1>
            <p className='text-wrap w-[100%] md:w-[900px] mt-4 md:mt-6 font-normal text-white text-center'>
              Welcome to the CarpeDM Ecuador Blog! Here, you'll find travel
              stories, expert tips, and insights to help you plan unforgettable
              adventures in Ecuador. Whether you're dreaming of exploring the
              Galapagos, the Amazon, or Ecuador’s hidden gems, our blog is the
              perfect starting point to fuel your wanderlust and guide your
              journey.
            </p>
          </div>
        </div>
      </div>
      <div className='w-full min-h-[600px] md:min-h-[1200px] mt-10 md:mt-20'>
        <div className='flex flex-col md:flex-row flex-wrap justify-center items-center gap-4 md:gap-8'>
          <BlogCard />
          <BlogCard />
          <BlogCard />
          <BlogCard />
          <BlogCard />
          <BlogCard />
        </div>
      </div>
      <Footer />
    </main>
  );
};

export default ListofBlogs;
