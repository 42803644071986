import "../assets/css/FAQs.css";
import React, { useState } from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";

const AccordionItem = ({ title, content, isOpen, toggle }) => (
    <>
        {/* <div className={`my-4 accordion-item ${!isOpen ? "accordion-item" : ""} `} > */}
        <div className="my-4 accordion-item rounded-[18px] p-4" >
            <div className="overflow-hidden">
                <button
                    type="button"
                    className="flex justify-between items-center w-full bg-[#FEFEFE] border-none"
                    onClick={toggle}
                >
                    <span className="font-semibold text-[#019BBB] text-[24px]">{title}</span>
                    <span>{isOpen ? <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <mask id="mask0_633_338" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="0" y="0" width="40" height="40">
                            <rect width="40" height="40" fill="#D9D9D9" />
                        </mask>
                        <g mask="url(#mask0_633_338)">
                            <path d="M8.33301 21.6673V18.334H31.6663V21.6673H8.33301Z" fill="#019BBB" />
                        </g>
                    </svg>
                        :
                        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M27.7663 3.33398C33.433 3.33398 36.6663 6.53398 36.6663 12.2173V27.784C36.6663 33.434 33.4497 36.6673 27.783 36.6673H12.2163C6.53301 36.6673 3.33301 33.434 3.33301 27.784V12.2173C3.33301 6.53398 6.53301 3.33398 12.2163 3.33398H27.7663ZM19.983 12.5173C19.2163 12.5173 18.5997 13.134 18.5997 13.9007V18.6007H13.883C13.5163 18.6007 13.1663 18.7507 12.8997 19.0007C12.6497 19.2673 12.4997 19.6157 12.4997 19.984C12.4997 20.7507 13.1163 21.3673 13.883 21.384H18.5997V26.1007C18.5997 26.8673 19.2163 27.484 19.983 27.484C20.7497 27.484 21.3663 26.8673 21.3663 26.1007V21.384H26.0997C26.8663 21.3673 27.483 20.7507 27.483 19.984C27.483 19.2173 26.8663 18.6007 26.0997 18.6007H21.3663V13.9007C21.3663 13.134 20.7497 12.5173 19.983 12.5173Z" fill="#019BBB" />
                        </svg>
                    }</span>
                </button>
            </div>
        </div>
        {isOpen && <p className="p-4 text-[#1E1E1E] text-[16px] font-[500]">{content}</p>}
    </>
);
const FAQs = () => {
    const [openIndex, setOpenIndex] = useState(null);
    const items = [
        { title: "What kind of tours do you offer?", content: "The Travel Better Pledge is our commitment to responsible tourism, promoting sustainable travel practices that protect the environment and benefit local communities." },
        { title: "What is the Travel Better Pledge?", content: "The Travel Better Pledge is our commitment to responsible tourism, promoting sustainable travel practices that protect the environment and benefit local communities." },
        { title: "How do I book a tour?", content: "The Travel Better Pledge is our commitment to responsible tourism, promoting sustainable travel practices that protect the environment and benefit local communities." },
        { title: "Is my payment secure?", content: "The Travel Better Pledge is our commitment to responsible tourism, promoting sustainable travel practices that protect the environment and benefit local communities." },
        { title: "Can I customize a tour?", content: "The Travel Better Pledge is our commitment to responsible tourism, promoting sustainable travel practices that protect the environment and benefit local communities." },

    ];

    const toggleItem = (index) => {
        setOpenIndex(openIndex === index ? null : index);
    };
    return (
        <div id="faqs-page" className="faqs-page">
            <div className="hero-section">
                <Navbar />
                <div className="header-section">
                    <div className="header">
                        <h1 className="heading">Frequently asked Questions</h1>
                        {/* <p className="para">At CarpeDM Adventures, we believe in seizing the day while doing more. Our mission is to provide transformative travel experiences that leave a positive impact on local communities and promote sustainable tourism.</p> */}
                    </div>
                </div>
            </div>
            <div className="px-[40px] py-[20px] mx-[30px] my-[0px]">
                {items.map((item, index) => (
                    <AccordionItem
                        key={index}
                        title={item.title}
                        content={item.content}
                        isOpen={openIndex === index}
                        toggle={() => toggleItem(index)}
                    />
                ))}
            </div>

            <Footer />
        </div>
    );
}

export default FAQs