import '../assets/css/Testimonials.css';
import React from 'react';
import Navbar from './Navbar';
import ReviewCard from './ReviewCard';
import Footer from './Footer';

const Testimonials = () => {
  const cards = [1, 2, 3, 4];
  return (
    <>
      <div id='reviews'>
        <Navbar />
        <div className='flex flex-col align-middle justify-center items-center h-[70vh]'>
          <h1 className='align-bottom text-4xl font-extrabold text-[#FEFEFE] text-[36px]'>
            Carpedm Reviews
          </h1>
        </div>
      </div>
      <div
        id='card-wrapper'
        className='flex flex-col justify-start items-center mt-8 h-auto p-10'
      >
        {cards.map((card, index) => (
          <div
            key={index}
            className={`w-full md:w-1/2 ${
              index % 2 === 0 ? 'self-start' : 'self-end'
            }`}
          >
            <ReviewCard />
          </div>
        ))}
      </div>
      <Footer />
    </>
  );
};

export default Testimonials;
