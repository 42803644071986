import '../../assets/css/blogs/blogs.css';

const ReadBlog = () => {
  return (
    <main className='w-full bg-slate-100 h-[1400px]'>
      <div className='flex flex-col items-center justify-center mt-3'>
        <div
          id='read-blog'
          className='bg-cover bg-center w-full max-w-[1400px] h-[300px] md:h-[600px] rounded-[24px] m-4 md:m-20 flex flex-col justify-center items-center'
        >
          <h1 className='text-wrap text-xl md:text-3xl text-white font-extrabold mt-20 md:mt-60'>
            Slow travel guide outline
          </h1>
          <p className='text-wrap font-medium w-[90%] md:w-[950px] text-sm md:text-xl text-slate-200 mt-2'>
            Slow travel focuses on immersing yourself in the culture, lifestyle,
            and unique experiences of a single destination, prioritizing quality
            and connection over visiting numerous places in a short time.
          </p>
        </div>
      </div>
      <div className='flex flex-col justify-center items-center'>
        <p className='w-[90%] max-w-[1400px] text-wrap ml-4 md:ml-26'>
          <strong className='text-2xl md:text-4xl text-[#019BBB]'>
            Slow Travel
          </strong>{' '}
          is a style of travel that emphasizes the experience of a destination
          over the number of destinations visited. It involves taking the time
          to get to know a place, rather than rushing through it to see as much
          as possible. Slow travel can involve staying in one location for an
          extended period, or moving at a leisurely pace between destinations.
        </p>
      </div>
      <div className='m-4 md:m-32'>
        <p className='text-[#019BBB]'>
          There are many benefits to slow travel. Some of the most notable
          include:
        </p>
        <ul>
          <li className='mt-4'>
            <strong className='font-extrabold text-lg md:text-xl'>
              Greater immersion in local culture
            </strong>
            <span>
              {' '}
              By spending more time in one place, you have the opportunity to
              get to know the local culture, customs, and way of life in a
              deeper way. You may have the chance to learn a new skill,
              participate in local activities, or make connections with locals
              that you might not have made if you were passing through.
            </span>
          </li>
          <li className='mt-6'>
            <strong className='font-extrabold text-lg md:text-xl'>
              A more relaxed and stress-free experience
            </strong>
            <span>
              {' '}
              Slow travel allows you to take your time and enjoy the journey,
              rather than feeling rushed or stressed out. This can lead to a
              more enjoyable and fulfilling travel experience overall.
            </span>
          </li>
          <li className='mt-6'>
            <strong className='font-extrabold text-lg md:text-xl'>
              Greater appreciation of the destination
            </strong>
            <span>
              {' '}
              Taking the time to really explore and experience a place can help
              you appreciate it more fully. You may discover hidden gems and
              local secrets that you might have missed if you were passing
              through.
            </span>
          </li>
          <li className='mt-6'>
            <strong className='font-extrabold text-lg md:text-xl'>
              Greater sustainability
            </strong>
            <span>
              {' '}
              Slow travel can also be more sustainable, as it involves staying
              in one place for an extended period of time and reducing your
              carbon footprint by traveling less frequently. This can also help
              support local businesses and communities.
            </span>
          </li>
        </ul>
      </div>
    </main>
  );
};

export default ReadBlog;
