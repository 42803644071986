import '../assets/css/Testimonials.css';

const ReviewsCard = () => {
  return (
    <div
      id='card'
      className='border-1 shadow-2xl rounded-[24px] w-full max-w-[800px] h-[200px] flex flex-col justify-center pt-10 pb-10 mx-auto'
    >
      <div>
        <hr className='w-[10px] h-[100px] rotate-180 absolute inline-flex bg-[#019BBB] border-none mt-14 rounded-lg' />
      </div>
      <div className='p-4 md:p-10'>
        <h1 className='text-[#019BBB] font-bold text-lg md:text-xl'>
          Maria, Jennifer, and Eleanor
        </h1>
        <p className='text-wrap text-slate-500 text-sm md:text-base'>
          "Galapagos – The trip to the Galapagos was amazing!! The Fragata was
          lovely, very clean, the food was gorgeous, the crew was lovely and we
          had great fun with them and our guide Ivan was just brilliant…we
          really enjoyed our time there on the Fragata and we were sorry to say
          goodbye!! Tell Paul we said thanks so much for organizing the trip for
          us, he was so helpful and friendly and we really appreciated it. The
          whole Galapagos experience couldn’t have gone any better!! And we will
          definitely be recommending Carpedm to any of our friends/family
          traveling to South America."
        </p>
      </div>
    </div>
  );
};

export default ReviewsCard;
