import React from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { PiGreaterThan } from "react-icons/pi";
import "../../assets/css/bookingTour/stepNav.css"

const StepNavigation = () => {
    const location = useLocation();
    const { id } = useParams(); // Get the tour id from the URL params

    // Determine the active step based on the current path
    const getActiveStep = () => {
        switch (location.pathname) {
            case `/tours/${id}/booking/tour-add-ons`:
                return 2;
            case `/tours/${id}/booking/add-travelers`:
                return 3;
            case `/tours/${id}/booking/review-booking`:
                return 4;
            default:
                return 1;
        }
    };

    const activeStep = getActiveStep();

    return (
        <div className="step-navigation">
            <div className={`step-item ${activeStep === 1 ? "active" : ""}`}>
                <Link to={`/tours/${id}/booking`}>Choose a package</Link>
            </div>
            <span className="step-arrow">
                <PiGreaterThan />
            </span>
            <div className={`step-item ${activeStep === 2 ? "active" : ""}`}>
                <Link to={`/tours/${id}/booking/tour-add-ons`}>Tour add-ons</Link>
            </div>
            <span className="step-arrow">
                <PiGreaterThan />
            </span>
            <div className={`step-item ${activeStep === 3 ? "active" : ""}`}>
                <Link to={`/tours/${id}/booking/add-travelers`}>Add travelers info</Link>
            </div>
            <span className="step-arrow">
                <PiGreaterThan />
            </span>
            <div className={`step-item ${activeStep === 4 ? "active" : ""}`}>
                <Link to={`/tours/${id}/booking/review-booking`}>Review booking & payment</Link>
            </div>
        </div>
    );
};

export default StepNavigation;
