const AlternatingLayoutAboutUs = ({ items }) => {
    return (
        <div className="flex flex-col space-y-8">
            {items.map((item, index) => (
                <div
                    key={index}
                    className={`flex flex-col md:flex-row items-center ${index % 2 === 0 ? '' : 'md:flex-row-reverse justify-between'}`}
                >
                    <div className="" style={{ width: "413px", height: "427px" }}>
                        <img style={{ width: "100%", height: "100%" }} src={item.image} alt={item.alt} className="" />
                    </div>
                    <div className="md:w-1/2 p-4">
                        <h3 className="text-xl mb-4 text-[#019BBB] text-[24px] font-[600]">{item.title}</h3>
                        {item.description.split('\n').map((paragraph, idx) => (
                            <p key={idx} className="text-[#1E1E1E] text-[16px] mb-4">
                                {paragraph}
                            </p>
                        ))}
                    </div>
                </div>
            ))}
        </div>
    );
};

export default AlternatingLayoutAboutUs;
