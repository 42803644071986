import React, { useState } from 'react'
import YourBooking from './YourBooking'
import "../../assets/css/bookingTour/tourAddOns.css"
import { GoChevronDown, GoChevronUp } from 'react-icons/go';
import Button from './Button';
import { useNavigate, useParams } from 'react-router-dom';


const TourAddOns = () => {
    const [activeDay, setActiveDay] = useState(null);
    const [selectedAttendees, setSelectedAttendees] = useState([]);
    const navigate = useNavigate();
    const { id } = useParams();

    const toggleDay = (day) => {
        setActiveDay(activeDay === day ? null : day);
    };

    const handleSelectAttendee = (attendee) => {
        setSelectedAttendees((prev) =>
            prev.includes(attendee)
                ? prev.filter((name) => name !== attendee)
                : [...prev, attendee]
        );
    };

    const handleContinue = () => {
        navigate(`/tours/${id}/booking/add-travelers`)
    }

    return (
        <div className="tour-add-ons-container">
            <div className="left-side">
                <div className="accordion-container">
                    {[1, 2].map((day) => (
                        <div key={day} className="accordion-day">
                            <div className="accordion-header" onClick={() => toggleDay(day)}>
                                <div>
                                    <h4>Day {day}:</h4>
                                    <time>Dec {18 + day}, 2024</time>
                                </div>
                                {activeDay === day ? <GoChevronUp className='icon' /> : <GoChevronDown className='icon' />}
                            </div>
                            {activeDay === day && (
                                <div className="accordion-content">
                                    <div className="addon">
                                        <div className="addon-header">
                                            <h5>Masai Mara Balloon Safari</h5>
                                            <p>1 hour | Masai Mara</p>
                                        </div>
                                        <p>
                                            Embark on a chimp trek with a park ranger/guide. See more than
                                            just chimpanzees in their natural environment. Learn about the
                                            ecosystem and keep an eye out for other animals.
                                        </p>
                                        <div className="addon-footer">
                                            <button className="add-remove-btn">Add to tours</button>
                                            <div className="price"><span>$28</span> Per person</div>
                                        </div>
                                    </div>
                                    <div className="attendee-selection">
                                        <h5>Select Attendees</h5>
                                        <label>
                                            <input
                                                type="checkbox"
                                                value="John Doe"
                                                onChange={() => handleSelectAttendee('John Doe')}
                                                checked={selectedAttendees.includes('John Doe')}
                                            />
                                            John Doe
                                        </label>
                                        <label>
                                            <input
                                                type="checkbox"
                                                value="Alice Wood"
                                                onChange={() => handleSelectAttendee('Alice Wood')}
                                                checked={selectedAttendees.includes('Alice Wood')}
                                            />
                                            Alice Wood
                                        </label>
                                    </div>
                                </div>
                            )}
                        </div>
                    ))}
                </div>
                <Button onClick={handleContinue}>Continue</Button>
            </div>
            {/* <button className="continue-btn">Continue</button> */}
            <YourBooking />
        </div>
    )
}

export default TourAddOns
