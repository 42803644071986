import React from 'react'
import "../../assets/css/bookingTour/PaymentSuccessful.css"
import svg from "../../assets/images/booking-successfull.svg";
import { Link } from 'react-router-dom';


const PaymentSuccessful = () => {
    return (
        <div>
            <BookingConfirmation />
            <BookingSummary />
        </div>
    )
}


const BookingConfirmation = () => {
    return (
        <div className="booking-confirmation">
            <div className="back-home">
                <Link to="/">Back to home page</Link>
            </div>
            <div className="confirmation-content">
                <img
                    src={svg}
                    alt="Booking Confirmed"
                    className="confirmation-image"
                />
                <h1>
                    Booking Confirmed <span>Adventure Awaits!</span>
                </h1>
                <p>
                    Thank you for booking with <a href="/">CarpeDM</a>! Your tour is confirmed, and we can't wait to take you on an unforgettable journey.
                </p>
            </div>
        </div>
    );
};

const BookingSummary = () => {
    return (
        <div className="booking-summary">
            <h4>Booking summary</h4>
            <div className="summary-details">
                <div className="summary-item">
                    <strong>Tour name</strong>
                    <p>Paris to Rome: Picnics & Pizza</p>
                </div>
                <div className="summary-item">
                    <strong>Tour dates</strong>
                    <p>October 03 - Oct 07</p>
                </div>
                <div className="summary-item">
                    <strong>Booking reference number</strong>
                    <p>#12354875</p>
                </div>
                <div className="summary-item">
                    <strong>Travelers names</strong>
                    <p>John Doe, Sarah Doe</p>
                </div>
            </div>
            <div className="summary-actions">
                <button className="btn-primary">Download confirmation</button>
                <button className="btn-secondary">Print booking details</button>
            </div>
        </div>
    );
};

export default PaymentSuccessful;