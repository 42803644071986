import React, { useState, useEffect } from 'react';
import { RiEdit2Line } from "react-icons/ri";
import { FaCalendarAlt } from 'react-icons/fa';
import '../../assets/css/bookingTour/booking.css';
import axios from 'axios';
import { RiArrowDropDownLine } from "react-icons/ri";

const YourBooking = () => {
    const [currencies, setCurrencies] = useState([]);
    const [selectedCurrency, setSelectedCurrency] = useState("");

    // Load the selected country from localStorage (if available)
    useEffect(() => {
        const savedCurrency = localStorage.getItem("selectedCurrency");
        if (savedCurrency)
            setSelectedCurrency(JSON.parse(savedCurrency));
    }, []);

    const handleSelection = (country) => {
        setSelectedCurrency(country);
        localStorage.setItem("selectedCurrency", JSON.stringify(country)); // Save the selected country to localStorage
    };

    useEffect(() => {
        const fetchData = async () => {
            const response = await axios.get('https://restcountries.com/v3.1/all?fields=flags,currencies');
            const data = response.data
                .map(country => {
                    const currencyKey = country.currencies ? Object.keys(country.currencies)[0] : null;
                    return {
                        flag: country.flags.svg || country.flags.png,
                        alt: country.flags.alt || `flag of the country`,
                        currency: currencyKey, // Set the currency key (could be null)
                        symbol: currencyKey ? country.currencies[currencyKey].symbol : '' // If currencyKey exists, get the symbol, otherwise fallback to an empty string
                    };
                })
                .filter(country => country.currency)
                .sort((a, b) => a.currency.localeCompare(b.currency)); // Sort by currency
            setCurrencies(data);

            if (!localStorage.getItem("selectedCurrency"))
                setSelectedCurrency(data[207]); //america
        }

        fetchData();
    }, []);

    return (
        <aside className="your-booking">
            <div className="currency-select">
                <h2>Your Booking</h2>
                <div className="custom-select">
                    <div className="selected-country">
                        <div>
                            {selectedCurrency && (
                                <>
                                    <img src={selectedCurrency.flag} alt={selectedCurrency.alt} />
                                    <span>{selectedCurrency.currency}</span>
                                </>
                            )}
                        </div>
                        <RiArrowDropDownLine style={{ fontSize: '2rem' }} />
                    </div>
                    <div className="dropdown">
                        {currencies.map((c, index) => (
                            <div
                                className="dropdown-option"
                                key={c.currency + index}
                                onClick={() => handleSelection(c)}
                            >
                                <img width={20} src={c.flag} alt={c.alt} />
                                {c.currency}
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            <div className="booking-details">
                <div className='booking-row'>
                    <label>October 3, 2024</label>
                    <span><FaCalendarAlt /></span>
                </div>
                <input type="text" className='booking-input' />
                <div className='booking-row'>
                    <label>Travelers: 2</label>
                    <span><RiEdit2Line /></span>
                </div>
                <input type="text" className='booking-input' />
                <div className='booking-row'>
                    <label>Total including all taxes:</label>
                    <span>{selectedCurrency.symbol} 1690</span>
                </div>
            </div>
        </aside>
    );
};

export default YourBooking;
