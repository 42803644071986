import { useEffect } from 'react';
import {
  Route,
  BrowserRouter as Router,
  Routes,
  useLocation,
} from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import AppLayout from './components/AppLayout';
import Homepage from './components/Homepage';
import Login from './components/Login';
import Signup from './components/Signup';
import TourPage from './components/TourPage';
import ViewTour from './components/ViewTour';
import AddTravelers from './components/bookingTour/AddTravelers';
import BookingPayment from './components/bookingTour/BookingPayment';
import BookingTour from './components/bookingTour/BookingTour';
import ChoosePackage from './components/bookingTour/ChoosePackage';
import PaymentSuccessful from './components/bookingTour/PaymentSuccessful';
import ReviewBooking from './components/bookingTour/ReviewBooking';
import ScrollToTop from './hooks/ScrollToTop';
import AgentSignUp from './components/AgentSignUp';
import ListofBlogs from './components/blogs/ListofBlogs';
import ReadBlog from './components/blogs/ReadBlog';
import AboutUs from './components/AboutUs';
import FAQs from './components/FAQs';
import GetInTouch from './components/GetInTouch';
import Testimonials from './components/Testimonials';
import PrivacyPolicy from './components/PrivacyPolicy';
import AccountPage from './components/accountPage/AccountPage';
import TourAddOns from './components/bookingTour/TourAddOns';

function Layout({ children }) {
  const location = useLocation();

  useEffect(() => {
    if (
      location.pathname === '/login' ||
      location.pathname === '/signup' ||
      location.pathname === '/agent-signup'
    ) {
      document.body.style.backgroundImage =
        'url("/images/rebe-adelaida-zunQwMy5B6M-unsplash.jpg")';
      document.body.style.backgroundSize = 'cover';
      document.body.style.backgroundPosition = 'center';
      document.body.style.backgroundRepeat = 'no-repeat';
      document.body.style.minHeight = '100vh';
      document.body.style.margin = '0';
      document.body.style.padding = '0';
      document.body.style.display = 'flex';
      document.body.style.justifyContent = 'flex-end';
      document.body.style.alignItems = 'center';
    } else {
      // Reset styles for other pages
      document.body.style = null;
    }
  }, [location]);

  return children;
}
function App() {
  return (
    <Router>
      <ScrollToTop />
      <Layout>
        <Routes>
          <Route path='/login' element={<Login />} />
          <Route path='/signup' element={<Signup />} />
          <Route path='/agent-signup' element={<AgentSignUp />} />
          <Route path='/' element={<Homepage />} />
          <Route
            path='/account'
            element={
              <AppLayout>
                <AccountPage />
              </AppLayout>
            }
          ></Route>
          <Route path='/about-us' element={<AboutUs />} />
          <Route path='/faqs' element={<FAQs />} />
          <Route path='/get-in-touch' element={<GetInTouch />} />
          <Route path='/reviews' element={<Testimonials />} />
          <Route path='/privacy-policy' element={<PrivacyPolicy />} />

          {/* Tours route */}
          <Route
            path='/tours'
            element={
              <AppLayout>
                <TourPage />
              </AppLayout>
            }
          />

          {/* View tour details */}
          <Route
            path='/tours/:id'
            element={
              <AppLayout>
                <ViewTour />
              </AppLayout>
            }
          />

          {/* Booking routes nested under /tours/:id/booking */}
          <Route
            path='/tours/:id/booking'
            element={
              <AppLayout>
                <BookingTour />
              </AppLayout>
            }
          >
            <Route index element={<ChoosePackage />} />
            <Route path='tour-add-ons' element={<TourAddOns />} />
            <Route path='add-travelers' element={<AddTravelers />} />
            <Route path='review-booking' element={<ReviewBooking />} />
            <Route path='booking-payment' element={<BookingPayment />} />
            <Route path='payment-successful' element={<PaymentSuccessful />} />
          </Route>

          {/* blogs route */}

          <Route path='/blogs' element={<ListofBlogs />} />
          <Route
            path='/read-blog'
            element={
              <AppLayout>
                <ReadBlog />
              </AppLayout>
            }
          ></Route>
        </Routes>
      </Layout>
      <ToastContainer />
    </Router>
  );
}

export default App;
