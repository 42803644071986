import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
// const baseURL = process.env.REACT_APP_MTA_WEB_VIEW_BASE_URL_DEVELOPMENT;

// export const getCropPhases = createAsyncThunk(
//     "CropDetails/getCropPhases",
//     async ({ cropId, province }) => {
//         try {
//             const token = localStorage.getItem("authToken");
//             const response = await axios.get(`${baseURL}/crops/${cropId}/${province}/phases`, {
//                 headers: {
//                     Authorization: `Bearer ${token}`
//                 }
//             });

//             return response.data;
//         } catch (error) {
//             console.log("ERROR", error);
//         }
//     });

export const BookingsSlice = createSlice({
    name: "Bookings",
    initialState: {
        bookings: [],
        isBookingLoading: false
    },
    reducers: {
        updateStateValue: (state, action) => {
            state.isBookingLoading = action.payload;
        },
    },
    extraReducers: (builder) => {
        // builder
        //     .addCase(getCropPhases.pending, (state) => {
        //         state.isCropDetailScreenLoading = true;
        //     })
        //     .addCase(getCropPhases.fulfilled, (state, action) => {
        //         state.cropPhases = action.payload;
        //         state.isCropDetailScreenLoading = false;
        //     })
        //     .addCase(getCropPhases.rejected, (state, action) => {
        //         state.isCropDetailScreenLoading = false;
        //     })
    },

});

export const {
    updateStateValue
} = BookingsSlice.actions;

export default BookingsSlice.reducer;
