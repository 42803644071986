import axios from 'axios';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { FaCalendarAlt, FaUser } from 'react-icons/fa';
import { GoChevronDown, GoChevronUp } from "react-icons/go";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import '../assets/css/ViewTour.css';
import vt3 from '../assets/images/vt-3.png';
import vt4 from '../assets/images/vt-4.png';
import vt5 from '../assets/images/vt-5.png';
import { clearSingleTourDetails, fetchSingleTourDetails } from '../redux/home';
import ViewTourHeader from './ViewTourHeader';
import TourDates from './bookingTour/TourDates';
const apiKey =
  import.meta.env?.REACT_APP_API_KEY ||
  'live_6e34a234be449f608524d6ddeb1c0d1486f22825';

const ViewTour = () => {
  const { id } = useParams(); // Get the tour id from the URL params
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { singleTourDetails, isLoading, error } = useSelector(
    (state) => state.Tours
  );
  const [itinerary, setItinerary] = useState(null);

  useEffect(() => {
    if (id) {
      dispatch(fetchSingleTourDetails({ id, apiKey }));
    }

    return () => {
      dispatch(clearSingleTourDetails()); // Clear details when component unmounts
    };
  }, [dispatch, id]);

  // Memoize the itineraries value to avoid unnecessary recalculations
  const itineraries = useMemo(() => {
    return singleTourDetails?.structured_itineraries?.length > 0
      ? singleTourDetails.structured_itineraries[0].href
      : null;
  }, [singleTourDetails]);
  console.log('itinerary::::::::::::', itinerary);

  useEffect(() => {
    if (!itineraries) return;

    const fetchItinerary = async () => {
      try {
        const response = await axios.get(itineraries, {
          headers: {
            'X-Application-Key': apiKey,
          },
        });
        setItinerary(response.data);
        console.log(response.data);
        console.log('Itinerary:', response.data);
      } catch (error) {
        console.error('Error fetching itinerary:', error);
      }
    };

    fetchItinerary();
  }, [itineraries]);

  const handleBookNowClick = () => {
    navigate(`/tours/${id}/booking`);
  };

  return (
    <div className='view-tour'>
      <ViewTourHeader tourDetails={singleTourDetails} />
      <ScenicViewGrid tourDetails={singleTourDetails} />
      <div className='trip-page'>
        <TripInformation
          handleBookNowClick={handleBookNowClick}
          tourDetails={singleTourDetails}
          itinerary={itinerary}
        />
        <TripSidebar
          handleBookNowClick={handleBookNowClick}
          tourDetails={singleTourDetails}
        />
      </div>
    </div>
  );
};

const ScenicViewGridd = ({ tourDetails }) => {
  const mapImage =
    tourDetails?.images?.find((img) => img.type === 'MAP')?.image_href ||
    'fallback-image-url.jpg';

  const bannerImage =
    tourDetails?.images?.find((img) => img.type === 'BANNER_HIRES')
      ?.image_href || 'fallback-image-url.jpg';

  return (
    <section className='picnic-pizza-grid'>
      <div className='grid-item large-image'>
        <img src={bannerImage} alt='City view' />
      </div>
      <div className='grid-item small-image'>
        <img src={mapImage} alt='Mountain view' />
      </div>
      <div className='grid-item small-image'>
        <img src={vt3} alt='Bike view' />
      </div>
      <div className='grid-item small-image'>
        <img src={vt4} alt='Snowy Mountain' />
      </div>
      <div className='grid-item small-image'>
        <img src={vt5} alt='City at dusk' />
        {/* <button>Show all photos</button> */}
      </div>
    </section>
  );
};

const ScenicViewGrid = ({ tourDetails }) => {
  const mapImage =
    tourDetails?.images?.find((img) => img.type === 'MAP')?.image_href ||
    'fallback-image-url.jpg';

  const bannerImage =
    tourDetails?.images?.find((img) => img.type === 'BANNER_HIRES')
      ?.image_href || 'fallback-image-url.jpg';

  const otherImages =
    tourDetails?.images?.filter(
      (img) => img.type !== 'MAP' && img.type !== 'BANNER_HIRES'
    ) || [];

  const displayedImages = otherImages.slice(0, 3);

  return (
    <section className='picnic-pizza-grid'>
      <div className='grid-item large-image'>
        <img src={bannerImage} alt='City view' />
      </div>
      <div className='grid-item small-image'>
        <img src={mapImage} alt='Mountain view' />
      </div>

      {displayedImages.map((image, index) => (
        <div className='grid-item small-image' key={index}>
          <img
            src={image.image_href || 'fallback-image-url.jpg'}
            alt={image.type}
          />
          {/* {index === displayedImages.length - 1 && (
            <button>Show all photos</button>
          )} */}
        </div>
      ))}
    </section>
  );
};

const ViewTripNavbar = ({ scrollToSection }) => {
  const [activeTab, setActiveTab] = useState('overview');

  const handleTabClick = (tab) => {
    setActiveTab(tab);
    scrollToSection(tab); // Scroll to the corresponding section
  };

  return (
    <div className='trip-navbar'>
      <ul className='nav-list'>
        <li
          className={`nav-item ${activeTab === 'overview' ? 'active' : ''}`}
          onClick={() => handleTabClick('overview')}
        >
          Overview
        </li>
        <li
          className={`nav-item ${activeTab === 'full-itinerary' ? 'active' : ''
            }`}
          onClick={() => handleTabClick('full-itinerary')}
        >
          Full Itinerary
        </li>
        <li
          className={`nav-item ${activeTab === 'trip-outline' ? 'active' : ''}`}
          onClick={() => handleTabClick('trip-outline')}
        >
          Trip Outline
        </li>
      </ul>
    </div>
  );
};

const TripInformation = ({ handleBookNowClick, tourDetails, itinerary }) => {
  const [selectedDays, setSelectedDays] = useState(4);

  const handlePrimaryClick = () => setSelectedDays(4);
  const handleSecondaryClick = () => setSelectedDays(6);
  // Create refs for each section
  const overviewRef = useRef(null);
  const fullItineraryRef = useRef(null);
  const tripOutlineRef = useRef(null);

  // Function to scroll to the respective section
  const scrollToSection = (section) => {
    if (section === 'overview') {
      overviewRef.current.scrollIntoView({ behavior: 'smooth' });
    } else if (section === 'full-itinerary') {
      fullItineraryRef.current.scrollIntoView({ behavior: 'smooth' });
    } else if (section === 'trip-outline') {
      tripOutlineRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const [openIndex, setOpenIndex] = useState(null); // Track which accordion is open

  const toggleAccordion = (index) => {
    setOpenIndex(openIndex === index ? null : index); // Toggle accordion open/close
  };

  return (
    <div className='trip-info'>
      <ViewTripNavbar scrollToSection={scrollToSection} />

      {/* Overview Section */}
      <div className='trip-description' ref={overviewRef}>
        <p>{tourDetails ? tourDetails.description : 'Loading...'}</p>
      </div>

      {/* Full Itinerary Section */}
      <div className='trip-itinerary' ref={fullItineraryRef}>
        <h2>Full Itinerary</h2>
        {/* <div className='fitn'>
          <button className='fitn-btn-primary'>4 days & 4 nights</button>
          <button className='fitn-btn-secondary'>6 days & 6 nights</button>
        </div> */}

        {/* <div className='fitn'>
          <button
            className={`${selectedDays === 4 ? 'fitn-btn-primary' : 'fitn-btn-secondary'}`}
            onClick={handlePrimaryClick}
          >
            4 Days & 4 Nights
          </button>
          <button
            className={`${selectedDays === 6 ? 'fitn-btn-primary' : 'fitn-btn-secondary'}`}
            onClick={handleSecondaryClick}
          >
            6 Days & 6 Nights
          </button>
        </div> */}

        <div className="itinerary">
          {itinerary && itinerary.days ? (
            itinerary.days.map((day, index) => (
              <div key={day.id} className={`day-details ${openIndex === index ? 'open' : ''}`}>
                <div onClick={() => toggleAccordion(index)} className="accordion-header">
                  <div>
                    <h3>
                      <span>Day {day.day}:</span> <span>{day.label}</span>
                    </h3>
                    <time dateTime="">{day.summary || '(No summary available)'}</time>
                  </div>
                  {openIndex === index ? <GoChevronUp className='icon' /> : <GoChevronDown className='icon' />}
                </div>
                <div className={`accordion-content ${openIndex === index ? 'show' : 'hide'}`}>
                  <p>{day.components[0]?.summary || 'No detailed description available.'}</p>
                  {index != 0 && day.components.find(c => c.type === "ACTIVITY")?.summary &&  (
                    <p className='activity'>{day.components.find(c => c.type === "ACTIVITY")?.summary}</p>
                  )}
                  <h4>Accommodation</h4>
                  <p className='hotel'>{day.components.find(c => c.type === "ACCOMMODATION")?.accommodation_dossier?.name || "Departure Day"}</p>
                </div>
              </div>
            ))
          ) : (
            <p>Loading itinerary...</p>
          )}
        </div>
        <button className='book-now' onClick={handleBookNowClick}>
          Book Now
        </button>
      </div>

      {/* Trip Outline Section */}
      {/* <div className='trip-outline' ref={tripOutlineRef}>
        <h2>Trip Outline</h2>
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Ea dolorem
          mollitia et? Consequuntur voluptate quas temporibus consequatur,
          soluta velit cum. Lorem ipsum dolor sit amet consectetur, adipisicing
          elit. Perspiciatis, nisi.
        </p>
        <div className='day-details'>
          <h3 className='included'>What's Included</h3>
          <ul>
            <li>Lorem ipsum dolor sit amet consectetur.</li>
            <li>Commodi iure delectus voluptatem alias dolores?</li>
            <li>Placeat dicta aspernatur eum quas adipisci.</li>
            <li>Culpa nisi accusantium repudiandae ab labore.</li>
            <li>Cumque nisi quae sed dicta vitae.</li>
          </ul>
        </div>
        <h4 className='important-note'>
          <span>Important*</span> Any thing that is not mentioned here is not
          included.
        </h4>
      </div> */}
    </div>
  );
};

const TripSidebar = ({ handleBookNowClick, tourDetails }) => {
  const [showTravelerModal, setShowTravelerModal] = useState(false);
  const [showDatePickerModal, setShowDatePickerModal] = useState(false);

  // State for traveler counts
  const [adultCount, setAdultCount] = useState(1);
  const [childrenCount, setChildrenCount] = useState(0);
  const [infantCount, setInfantCount] = useState(0);

  const handleTravelerClick = () => {
    setShowTravelerModal(true);
  };
  const handleDepartureDateClick = () => {
    setShowDatePickerModal(true);
  };

  const handleCloseModal = () => {
    setShowTravelerModal(false);
    setShowDatePickerModal(false);
  };

  // Functions to handle increment and decrement
  const incrementAdult = () => setAdultCount(adultCount + 1);
  const decrementAdult = () =>
    setAdultCount(adultCount > 1 ? adultCount - 1 : 1);

  const incrementChildren = () => setChildrenCount(childrenCount + 1);
  const decrementChildren = () =>
    setChildrenCount(childrenCount > 0 ? childrenCount - 1 : 0);

  const incrementInfant = () => setInfantCount(infantCount + 1);
  const decrementInfant = () =>
    setInfantCount(infantCount > 0 ? infantCount - 1 : 0);

  const priceInfo =
    tourDetails?.advertised_departures?.find((dep) =>
      ['USD', 'AUD', 'CHF', 'KRW', 'EUR'].includes(dep.currency)
    ) || tourDetails?.advertised_departures?.[0];

  const price = priceInfo ? priceInfo.amount : null;

  return (
    <div className='trip-sidebar'>
      <div className='titles'>
        <h3>${price}</h3>
        <p>4 days 3 nights</p>
      </div>
      <div className='selection-box'>
        <button className='selection-item' onClick={handleDepartureDateClick}>
          <FaCalendarAlt className='icon' />
          <span>Departure date</span>
        </button>
        <div className='divider'></div>
        <button className='selection-item' onClick={handleTravelerClick}>
          <FaUser className='icon' />
          <span>Travelers</span>
        </button>
      </div>
      <div className='booking-buttons'>
        <button className='btn-primary' onClick={handleBookNowClick}>
          Book now
        </button>
        <button className='btn-secondary'>Save to Wishlist</button>
        <div className='total-section'>
          <p>Total including all taxes</p>
          <h2>${price}</h2>
        </div>
      </div>

      {showTravelerModal && (
        <div className='modal-overlay' onClick={handleCloseModal}>
          <div className='modal-content' onClick={(e) => e.stopPropagation()}>
            <div className='traveler-selection'>
              <h3>Select Travelers</h3>
              <div className='traveler-options'>
                <div>
                  <div className='traveler-type'>
                    <h4>Adults</h4>
                    <p>Age 13+</p>
                  </div>
                  <div className='traveler-count'>
                    <button onClick={decrementAdult}>-</button>{' '}
                    <span>{adultCount}</span>{' '}
                    <button onClick={incrementAdult}>+</button>
                  </div>
                </div>
                <div>
                  <div className='traveler-type'>
                    <h4>Children</h4>
                    <p>Age 2 - 12</p>
                  </div>
                  <div className='traveler-count'>
                    <button onClick={decrementChildren}>-</button>{' '}
                    <span>{childrenCount}</span>{' '}
                    <button onClick={incrementChildren}>+</button>
                  </div>
                </div>
                <div>
                  <div className='traveler-type'>
                    <h4>Infants</h4>
                    <p>Under 2</p>
                  </div>
                  <div className='traveler-count'>
                    <button onClick={decrementInfant}>-</button>{' '}
                    <span>{infantCount}</span>{' '}
                    <button onClick={incrementInfant}>+</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {showDatePickerModal && (
        <div className='modal-overlay' onClick={handleCloseModal}>
          <div
            className='date-modal-content'
            onClick={(e) => e.stopPropagation()}
          >
            <div className='traveler-selection'>
              <h3>Pick Dates</h3>
              <TourDates />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ViewTour;
