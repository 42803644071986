// cityRecommendations.js
export const cityRecommendations = [
  'New York City',
  'London',
  'Tokyo',
  'Sydney',
  'Paris',
  'Rome',
  'Cairo',
  'Dubai',
  'Cape Town',
  'Buenos Aires',
  'Mumbai',
  'Hong Kong',
  'Los Angeles',
  'Berlin',
  'Toronto',
  'Kenya',
  'Istanbul',
  'Singapore',
  'Mexico City',
  'Barcelona',
  'Seoul',
  'Antarctica',
  'South America'
];
