import React, { useState, useEffect } from 'react';
import '../assets/css/Login.css';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { login } from '../redux/authSlice';
import logo from '../assets/images/logo.png';

const Login = () => {
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });

  const navigate = useNavigate();
  const dispatch = useDispatch(); // Initialize dispatch
  const { isAuthenticated, isLoading, error } = useSelector(
    (state) => state.auth
  ); // Get authentication state

  useEffect(() => {
    if (isAuthenticated) {
      navigate('/'); // Redirect to the homepage if authenticated
    }
  }, [isAuthenticated, navigate]);

  useEffect(() => {
    if (error) {
      toast.error(error);
    }
  }, [error]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(login(formData));
  };

  return (
    <>
      <div className='logo'>
        <a href='/'>
          <img src={logo} width={'100px'} alt='logo' />
        </a>
      </div>
      <div className='page-container'>
        <div className='login-form'>
          <div className='title'>
            <h2>Welcome Back, Explore!</h2>
            <p>Log in to access your adventure, bookings and travel plans</p>
          </div>
          <form onSubmit={handleSubmit}>
            <div>
              <input
                type='text'
                name='email'
                placeholder='Enter Your Email'
                onChange={handleChange}
              />
            </div>
            <div>
              <input
                type='password'
                name='password'
                placeholder='Enter Your Password'
                onChange={handleChange}
              />
            </div>
            <div className='checkbox-container'>
              <div>
                <input type='checkbox' className='checkbox' />
                <span>Keep me logged in</span>
              </div>
              <span className='forgot-password'>Forgot Password?</span>
            </div>
            <div className='btn'>
              <button type='submit' disabled={isLoading}>
                {isLoading ? 'Logging in...' : 'Login'}
              </button>
            </div>
          </form>
          <div className='separator'>
            <hr />
            <p>or</p>
            <hr />
          </div>
          <div className='sign-login'>
            <p>
              Don't have an account? <a href='/signup'>Create an account</a>
            </p>
          </div>
          <div className='agent-login'>
            <p>Are you an agent?</p>
          </div>
          <div>
            <button className='agent-btn-login'>Continue to Sherpa</button>
          </div>
          <div className='policy-container'>
            <span>Privacy policy</span>
            <span>Inactive account?</span>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
