import React from 'react';
import '../../assets/css/blogs/blogs.css';
import img from '../../assets/images/vt-2.png';
import { useNavigate } from 'react-router-dom';

const BlogCard = () => {
  const navigate = useNavigate();

  const handleReadMore = () => {
    navigate('/read-blog');
  };
  return (
    <div className='w-[90%] max-w-[450px] rounded-[24px] overflow-hidden shadow-xl m-4 h-auto md:h-[550px] flex flex-col justify-center items-center border-[0.10px] border-solid'>
      <img
        className='w-[90%] max-w-[400px] h-[200px] md:h-[300px] mt-6'
        src={img}
        alt='Blog'
      />
      <div className='px-4 md:px-6 py-4 mt-2'>
        <p className='text-slate-500'>December 26, 2024</p>
        <h1 className='font-extrabold text-lg md:text-md mb-2 text-[#019BBB]'>
          Becoming the best version of you
        </h1>
        <p className='text-gray-700 text-sm md:text-md mt-2'>
          Escape the fast-paced life and embrace the simplicity of slow travel
          in Ecuador - A journey of self-discovery.
        </p>
      </div>
      <div className='px-4 md:px-6 pt-4 pb-2 mt-4 self-end mb-4'>
        <button
          className='bg-[#019BBB] hover:bg-cyan-700 text-white font-bold py-2 px-4 md:p-4 rounded-[12px] outline-none border-none cursor-pointer'
          onClick={handleReadMore}
        >
          Read Full blog
        </button>
      </div>
    </div>
  );
};

export default BlogCard;
