import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import '../../assets/css/bookingTour/booking.css';
import Button from './Button';
import TourDates from './TourDates';
import YourBooking from './YourBooking';

const ChoosePackage = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const handleContinue = () => {
        navigate(`/tours/${id}/booking/tour-add-ons`)
    }
    return (
        <div className="booking-content">
            <div className="tour-dates">
                <h2>Tour Dates</h2>
                <TourDates />
                {/* Select Package */}
                <div className="select-package">
                    <h2>Select a Package</h2>
                    <div className="package">
                        <input type="checkbox" name="package" />
                        <div>
                            <h4>4 days & 3 Nights</h4>
                            <small>3 iconic cities, Paris, Rome & Switzerland</small>
                        </div>
                        <p>$560</p>
                    </div>
                    <div className="package">
                        <input type="checkbox" name="package" />
                        <span>
                            <h4>5 days & 4 Nights</h4>
                            <small>3 iconic cities, Paris, Rome & Switzerland</small>
                        </span>
                        <p>$750</p>
                    </div>
                </div>

                {/* Payment Options */}
                <footer className="payment-options">
                    <h2>Payment Options</h2>
                    <label>
                        <input type="checkbox" />
                        Pay full amount - $1450
                    </label>
                    <Button onClick={handleContinue}>Continue</Button>
                </footer>
            </div>
            <YourBooking />
        </div>
    );
};

export default ChoosePackage;