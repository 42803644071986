import Footer from './Footer';
import Navbar from './Navbar';

const AppLayout = ({ children }) => {
 return(
  <div>
   <Navbar></Navbar>
   <main>{children}</main>
   <Footer/>
  </div>
 )
}

export default AppLayout;